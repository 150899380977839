import React, { useEffect, useState } from 'react';
import { AdminButton, AdminCategoryTitle } from '../../../AdminPage-styling';
import { ax } from '../../../../Utils';
import Table from '../../../AdminComponents/Table';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import EditSchemaPopup from './EditSchemaPopup';
import { useQueryClient } from '@tanstack/react-query';

export interface Attribute {
  name: string;
  type: string;
  description: string;
  required: boolean;
}

export interface AttributeType {
  name: string;
}

export interface CustomDataModel {
  id: string;
  name: string;
  description: string;
  object_schema: Record<string, Attribute>;
}

const CustomDataModelsTable = () => {
  const [models, setModels] = useState<CustomDataModel[]>([]);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [currentModel, setCurrentModel] = useState<CustomDataModel | null>(null);

  const queryClient = useQueryClient();

  useEffect(() => {
    fetchModels();
  }, []);

  const fetchModels = async () => {
    try {
      const response = await ax.get('/custom_data_model/all/');
      console.log('API Response:', response.data);
      setModels(response.data);
      console.log('Models State:', response.data);
    } catch (error) {
      toast.error('Failed to fetch custom data models.');
    }
  };

  const handleEdit = (model: CustomDataModel) => {
    setCurrentModel(model);
    setShowEditPopup(true);
  };

  const handleSaveSchema = (updatedModel: CustomDataModel) => {
    queryClient.invalidateQueries({ queryKey: ['custom_data_models'] });
    setShowEditPopup(false);
  };

  const handleAddModel = () => {
    const newModel: CustomDataModel = {
      id: '',
      name: '',
      description: '',
      object_schema: {},
    };
    setModels([...models, newModel]);
    setCurrentModel(newModel);
    setShowEditPopup(true);
  };

  const handleDelete = async (modelId: string) => {
    if (modelId) {
      try {
        await ax.delete(`/custom_data_model/${modelId}`);
        toast.success('Custom data model deleted successfully!');
        fetchModels();
      } catch (error) {
        toast.error('Failed to delete custom data model.');
        return;
      }
    }

    setModels(models.filter(model => model.id !== modelId));
  };

  const columns = [
    {
      header: 'Name',
      accessor: 'name',
      cell: ({ row }: { row: { original: CustomDataModel } }) => (
        <span>{row.original.name}</span>
      ),
    },
    {
      header: 'Description',
      accessor: 'description',
      cell: ({ row }: { row: { original: CustomDataModel } }) => (
        <span>{row.original.description}</span>
      ),
    },
    {
      header: 'Actions',
      cell: ({ row }: { row: { original: CustomDataModel } }) => (
        <div>
          <AdminButton onClick={() => handleEdit(row.original)}>Edit</AdminButton>
          <AdminButton onClick={() => handleDelete(row.original.id)}>Delete</AdminButton>
        </div>
      ),
    },
  ];

  console.log('Table Data:', models);

  return (
    <div>
      <AdminCategoryTitle>Custom Data Models</AdminCategoryTitle>
      <Table columns={columns} data={models} />
      <AdminButton onClick={handleAddModel}>Create New Model</AdminButton>
      {showEditPopup && currentModel && (
        <EditSchemaPopup
          modelId={currentModel.id}
          initialSchema={currentModel}
          onClose={() => setShowEditPopup(false)}
          onSave={handleSaveSchema}
        />
      )}
    </div>
  );
};

export default CustomDataModelsTable; 