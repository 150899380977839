import { useQuery } from "@tanstack/react-query";
import { MarketingContent } from "../Types";
import { ax } from "../Utils";


export const useMarketingContentList = (variant_id: string | undefined) => {
  return useQuery<MarketingContent[], Error>({
    queryKey: ['marketing', 'content', 'all', 'list', {variant_id}],
    queryFn: (): Promise<MarketingContent[]> => ax.get(`/marketing_content/all/list/?variant_id=${variant_id}`)
      .then(res => {
        console.log('res', res.data);
        return res.data.sort((a: MarketingContent, b: MarketingContent) => new Date(b.last_updated_at).getTime() - new Date(a.last_updated_at).getTime())
      }),
    refetchOnReconnect: "always",
    refetchInterval: 1000 * 60 * 3,
    enabled: !!variant_id,
  });
};