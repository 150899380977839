import { useQueryClient } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { ax } from "../Utils";
import { Template } from "../Types";


export const useTemplateMutation = () => {
  const queryClient = useQueryClient();

  const templateMutation = useMutation({
    mutationFn: (requestBody: any) => {
      const result = requestBody.id ?
        ax.patch('/template', requestBody)
        : ax.post('/template', requestBody)
      return result;
    },
    onSuccess: (response, variables) => {
      if (!variables.id) {
        queryClient.setQueryData(['template', 'all'], (oldData: Template[]) => {
          return [...oldData, response.data];
        });
      } else {
        queryClient.setQueryData(['template', 'all'], (oldData: Template[]) => {
          return oldData.map(template => template.id === variables.id ? response.data : template);
        });
      }
    },
  });

  return templateMutation;
}