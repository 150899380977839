import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { ax } from '../../Utils';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { Campaign, Variant, MarketingContent, TCampaignSchema, campaignSchema } from '../../Types';
import { useCampaignVariantMutation } from '../../Mutations/CampaignVariantMutation';
import { useCampaignList } from '../../Queries/useCampaignList';
import { useMarketingCampaignMutation } from '../../Mutations/MarketingCampaignMutation';
import Swal from 'sweetalert2';
import { useMarketingContentList } from '../../Queries/useMarketingContentList';
import { useMarketingContentMutation } from '../../Mutations/MarketingContentMutation';
import { debounce } from 'lodash';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { AddButton, CampaignCard } from '../MarketingPageNew-styling';

// Create a debounced version of the patch function
const debouncedPatch = debounce((url, data) => {
  ax.patch(url, data);
}, 2000); // 2 seconds delay



type CampaignListProps =  {
  isCollapsed: boolean;
  onToggleCollapse: () => void;
}



const Sidebar = ({ isCollapsed = false, onToggleCollapse }: CampaignListProps) => {
  const { campaignId, variantId, contentId } = useParams<{ campaignId?: string; variantId?: string; contentId?: string }>();
  const navigate = useNavigate();
  // const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  // const [variants, setVariants] = useState<Variant[]>([]);
  // const [contents, setContents] = useState<MarketingContent[]>([]);
  // const [newCampaignName, setNewCampaignName] = useState('');
  // const [newCampaignDescription, setNewCampaignDescription] = useState('');
  // const [expandedCampaignId, setExpandedCampaignId] = useState<string | null>(null);
  // const [expandedVariantId, setExpandedVariantId] = useState<string | null>(null);
  // const [selectedVariant, setSelectedVariant] = useState<Variant | null>(null);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  // const [selectedCampaignId, setSelectedCampaignId] = useState<string | null>(null);
  // const [isVariantDialogOpen, setIsVariantDialogOpen] = useState(false);
  // const [newVariantName, setNewVariantName] = useState('');
  // const [newVariantDescription, setNewVariantDescription] = useState('');
  
  const {data: campaigns} = useCampaignList();
  const {data: contents} = useMarketingContentList(variantId);
  
  const campaignVariantMutation = useCampaignVariantMutation();
  const marketingCampaignMutation = useMarketingCampaignMutation();
  const marketingContentMutation = useMarketingContentMutation();


  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<TCampaignSchema>({
    resolver: zodResolver(campaignSchema),
  });

  const onSubmit = (data: TCampaignSchema) => {
    console.log("submitting form", data);
    const requestBody = {
      name: data.name,
      description: data.description || '',
    };
    try {
      marketingCampaignMutation.mutate(requestBody, {
        onSuccess: (response) => {
          navigate(`/marketing/${response.data.id}`);
          setIsCreateDialogOpen(false);
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Request failed!',
        text: 'Contact support if the problem persists.',
      });
    }
  }

  // const handleCreateCampaign = () => {
  //   if (newCampaignName && newCampaignDescription) {
  //     const newCampaign = { name: newCampaignName, description: newCampaignDescription };
  //     try {
  //       marketingCampaignMutation.mutate(newCampaign, {
  //         onSuccess: () => {
  //           setNewCampaignName('');
  //           setNewCampaignDescription('');
  //         }
  //       });
  //     } catch (error) {
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Request failed!',
  //         text: 'Contact support if the problem persists.',
  //       });
  //     }
  //   }
  // };

  const handleAddVariant = (campaignId: string) => {
    const newVariant = { name: 'New Variant', description: '', campaign_id: campaignId };
    try {
      campaignVariantMutation.mutate(newVariant, {
        onSuccess: (response) => {
          navigate(`/marketing/${campaignId}/${response.data.id}`);
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Request failed!',
        text: 'Contact support if the problem persists.',
      });
    }
  };

  const handleAddContent = (campaignId: string, variantId: string) => {
    const newContent = { content_title: 'New Content', variant_id: variantId, campaign_id: campaignId };
    try {
      marketingContentMutation.mutate(newContent, {
        onSuccess: (response) => {
          const contentId = response.data.id;
          navigate(`/marketing/${campaignId}/${variantId}/${contentId}`);
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Request failed!',
        text: 'Contact support if the problem persists.',
      });
    }
  };


  return (
    <Box>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={() => setIsCreateDialogOpen(true)}
        sx={{ mb: 2 }}
      >
        {isCollapsed ? '+' : 'New Campaign'}
      </Button>

      {/* Campaign creation dialog */}
      <Dialog open={isCreateDialogOpen} onClose={() => setIsCreateDialogOpen(false)}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Create New Campaign</DialogTitle>
          <DialogContent>
            <TextField
              {...register('name')}
              label="Campaign Name"
              fullWidth
              sx={{ mb: 2 }}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
            <TextField
              {...register('description')}
              label="Description"
              fullWidth
              multiline
              rows={4}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              setIsCreateDialogOpen(false);
              reset();
            }}>Cancel</Button>
            <Button 
              type="submit"
              variant="contained" 
              color="primary"
            >
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Display campaigns with hierarchy */}
      {campaigns?.map(campaign => (
        <div key={campaign.id}>
          <CampaignCard 
            $isSelected={campaign.id === campaignId} 
            $expanded={!isCollapsed} 
            onClick={() => {
              navigate(`/marketing/${campaign.id}`);
            }}
          >
            {campaign.name}
          </CampaignCard>
          {campaignId === campaign.id && 
            <div style={{ paddingLeft: '20px' }}>
              {campaign.variants?.map(variant => (
                <div key={variant.id}>
                  <CampaignCard 
                    key={variant.id}
                    $isSelected={variant.id === variantId} 
                    $expanded={!isCollapsed} 
                    onClick={() => {
                      navigate(`/marketing/${campaign.id}/${variant.id}`);
                    }}
                  >
                    {variant.name}
                  </CampaignCard>
                  {variantId === variant.id && 
                    <div style={{ paddingLeft: '20px' }}>
                      {contents?.map(content => (
                        <CampaignCard 
                          key={content.id}
                          $isSelected={content.id === contentId} 
                          $expanded={!isCollapsed} 
                          onClick={() => navigate(`/marketing/${campaign.id}/${variant.id}/${content.id}`)}
                        >
                          {content.content_title || '*unnamed*'}
                        </CampaignCard>
                      ))}
                    <AddButton 
                      $expanded={!isCollapsed} 
                      onClick={() => handleAddContent(campaign.id, variant.id)}
                    >
                      {isCollapsed ? '+' : 'Add Content'}
                    </AddButton>
                    </div>
                  }              
                </div>
              ))}
              <AddButton
                $expanded={!isCollapsed} 
                onClick={() => handleAddVariant(campaign.id)} 
              >
                {isCollapsed ? '+' : 'Add Variant'}
              </AddButton>
            </div>
          }

        </div>
      ))}
    </Box>
  );
};

export default Sidebar;
