import { AccessibleUnitOrg } from "../Types";
import { ax } from "../Utils";
import { useQuery } from "@tanstack/react-query"


export const useUsersAccessibleUnits = () => {
  return useQuery<AccessibleUnitOrg[], Error>({
    queryKey: ['users', 'accessible-unit-org'],
    queryFn: () => ax.get('/users/accessible-unit-org/').then(res => res.data),
    refetchOnReconnect: true,
  });
};