import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { ax } from '../../Utils';
import { useParams } from 'react-router-dom';
import { AdminInputError, AdminSettingContainer, AdminSettingInput, AdminSettingTextarea } from '../../AdminPage/AdminPage-styling';
import { MarketingSettingText } from '../MarketingPageNew-styling';
import { useForm } from 'react-hook-form';
import { CampaignInfo, campaignSchema, TCampaignSchema } from '../../Types';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMarketingCampaignMutation } from '../../Mutations/MarketingCampaignMutation';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useCampaignInfo } from '../../Queries/useCampaignInfo';

const CampaignDetails = () => {
  const { campaignId } = useParams<{ campaignId: string }>();
  // const [campaignData, setCampaignData] = useState<CampaignInfo | null>(null);
  // const [editedName, setEditedName] = useState(campaignData?.name || '');
  // const [editedDescription, setEditedDescription] = useState(campaignData?.description || '');

  const { data: campaignInfo } = useCampaignInfo(campaignId);

  const marketingCampaignMutation = useMarketingCampaignMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<TCampaignSchema>({
    resolver: zodResolver(campaignSchema),
  });

  const onSubmit = async (data: TCampaignSchema) => {
    console.log("submitting form", data);
    const requestBody = {
      id: campaignId,
      name: data.name,
      description: data.description || '',
    };
    try {
      marketingCampaignMutation.mutate(requestBody, {
        onSuccess: () => {
          toast.success("Campaign updated successfully!");
        }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Request failed!',
        text: 'Contact support if the problem persists.',
      });
    }
  }

  // useEffect(() => {
  //   if (campaignId) {
  //     ax.get(`/marketing_campaign?obj_id=${campaignId}`).then(response => {
  //       setCampaignData(response.data);
  //       setEditedName(response.data.name);
  //       setEditedDescription(response.data.description);
  //     });
  //   }
  // }, [campaignId]);

  // const handleSave = () => {
  //   const updatedCampaign = { name: editedName, description: editedDescription };
  //   ax.patch(`/marketing_campaign/${campaignId}/`, updatedCampaign).then(response => {
  //     setCampaignData(response.data);
  //     alert('Campaign details updated successfully!');
  //   });
  // };

  // if (!campaignData) {
  //   return <div>Loading...</div>;
  // }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AdminSettingContainer>
        <MarketingSettingText>Campaign Name</MarketingSettingText>
        <AdminSettingInput
          placeholder="Campaign Name"
          style={{ fontSize: '1.5rem', padding: '0.75rem', boxShadow: 'none' }}
          defaultValue={campaignInfo?.name || ''}
          {...register("name")}
        />
        {errors.name && (
          <AdminInputError>{errors.name.message}</AdminInputError>
        )}
      </AdminSettingContainer>
      <AdminSettingContainer>
        <MarketingSettingText>Campaign Description</MarketingSettingText>
        <AdminSettingTextarea
          placeholder="Campaign Description"
          style={{ boxShadow: 'none' }}
          defaultValue={campaignInfo?.description || ''}
          rows={4}
          {...register("description")}
        />
      </AdminSettingContainer>
      <Button 
        type="submit"
        variant="contained" 
        color="primary"
        disabled={isSubmitting}
      >
        Save
      </Button>
    </form>
  );
};

export default CampaignDetails;