// This file will contain the component for listing website scrape configurations.
// Import necessary React and styling components
import React, { useEffect, useState } from 'react';
import { ax } from '../../../../Utils';
import { toast } from 'react-toastify';
import { AdminCategoryTitle } from '../../../AdminPage-styling';
import { Collapse } from 'react-collapse';
import { Modal, Box, Typography, Button, Select, MenuItem, FormControl, InputLabel, IconButton } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { FAQ, Product, Web, CustomData } from '../../../../Types'; // Assuming Types.tsx exports the necessary components or types
import LazyDataComponent from './LazyDataComponent';
import { AdminPopupWindow } from '../../../../AdminPage/AdminPage-styling';
import { PopupOverlay } from "../../../../ErrandsPage/ErrandsPage-styling";
import produce from 'immer'; // Added Immer for immutable updates

type DataModel = {
  id: string;
  name: string;
};

// Define the types for WebPage and DataModel
type WebPageType = {
  id: string | null;
  url: string;
  data_model_id: string | null;
  data_model_name: string | null;
  data_model_type: string | null;
  last_refreshed_at: string | null;
  children: WebPageType[];
  faq: string[] | null;
  product: string[] | null;
  custom_data: string[] | null;
  web: string[] | null;
};

export const ProductComponent = ({ product }: { product: Product }) => (
  <div>
    <Typography variant="subtitle1">
      <strong>Product Name:</strong> {product.name}
    </Typography>
    <Typography>
      <strong>SKU:</strong> {product.sku}
    </Typography>
    <Typography>
      <strong>Price:</strong> {product.price} {product.currency}
    </Typography>
    {/* Add more fields as necessary */}
  </div>
);

export const FAQComponent = ({ faq }: { faq: FAQ }) => (
  <div>
    <Typography variant="subtitle1">
      <strong>Title:</strong> {faq.title}
    </Typography>
    <Typography>
      <strong>Question:</strong> {faq.question_text}
    </Typography>
    <Typography>
      <strong>Answer:</strong> {faq.answer_text}
    </Typography>
    {/* Add more fields as necessary */}
  </div>
);

export const WebComponent = ({ web }: { web: Web }) => {
  if (!web) {
    return <Typography>No Web Data Available</Typography>;
  }

  return (
    <div>
      {/* Uncomment if URL is needed */}
      {/* <Typography variant="subtitle1"><strong>URL:</strong> {web.url}</Typography> */}
      <Typography>
        <strong>Summary:</strong> {web.summary || 'No Summary Provided'}
      </Typography>
      <Typography>
        <strong>Text:</strong> {web.text || 'No Text Provided'}
      </Typography>
      {/* Add more fields as necessary */}
    </div>
  );
};

const WebPageList = () => {
  const [webPages, setWebPages] = useState<WebPageType[]>([]);
  const [openGroups, setOpenGroups] = useState<{ [key: string]: boolean }>({});
  const [editOpen, setEditOpen] = useState(false);
  const [currentEditPage, setCurrentEditPage] = useState<WebPageType | null>(null);
  const [dataModels, setDataModels] = useState<DataModel[]>([]);
  const [unsavedChanges, setUnsavedChanges] = useState<{ [key: string]: boolean }>({});
  const [viewDataOpen, setViewDataOpen] = useState(false);
  const [viewDataContent, setViewDataContent] = useState<WebPageType | null>(null);
  const [selectedConfig, setSelectedConfig] = useState<string | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [configs, setConfigs] = useState<DataModel[]>([]);

  // Helper function to generate a unique key based on the URL hierarchy
  const generateUniqueKey = (page: WebPageType, parentKey: string = ''): string => {
    return parentKey ? `${parentKey}/${page.url}` : page.url;
  };

  // Recursive helper to update data_model_id and data_model_name
  const updateDataModelForAllChildren = (
    page: WebPageType,
    dataModelId: string | null,
    dataModelName: string | null
  ) => {
    page.data_model_id = dataModelId;
    page.data_model_name = dataModelName;
    if (page.children && page.children.length > 0) {
      page.children.forEach((child) => updateDataModelForAllChildren(child, dataModelId, dataModelName));
    }
  };

  const toggleGroup = (key: string) => {
    setOpenGroups((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleDataModelChange = (key: string, newDataModelId: string) => {
    console.log('DataModelChange:', { key, newDataModelId });

    setWebPages((prevWebPages) =>
      produce(prevWebPages, (draft) => {
        const updatePages = (pages: WebPageType[], parentKey: string = '') => {
          pages.forEach((page) => {
            const currentKey = generateUniqueKey(page, parentKey);
            if (currentKey === key) {
              const selectedModel = dataModels.find((model) => model.id === newDataModelId) || null;
              console.log('Updating page:', { currentKey, newDataModelId });
              page.data_model_id = newDataModelId;
              page.data_model_name = selectedModel ? selectedModel.name : null;
            }
            if (page.children.length > 0) {
              updatePages(page.children, currentKey); // Pass currentKey as parentKey
            }
          });
        };
        updatePages(draft);
      })
    );

    setUnsavedChanges((prev) => ({ ...prev, [key]: true }));
  };

  const handleSaveDataModel = async (key: string) => {
    try {
      const page = findPageByKey(webPages, key);
      if (page) {
        if (!page.data_model_id) {
          throw new Error('Data Model ID is null');
        }
        await ax.patch('/web_page/set_data_model/', {
          web_page_id: page.id,
          data_model_id: page.data_model_id,
        });
        toast.success('Data model set successfully');
        setUnsavedChanges((prev) => ({ ...prev, [key]: false }));
      } else {
        throw new Error('Page not found');
      }
    } catch (error) {
      console.error('Error in handleSaveDataModel:', error);
      toast.error('Error setting data model');
    }
  };

  const handleBatchSetDataModel = async (key: string) => {
    try {
      const page = findPageByKey(webPages, key);
      if (!page) {
        throw new Error('Page not found');
      }

      const payload = {
          data_model_id: page.data_model_id,
          url: page.url,
      };

      console.log('Payload:', payload);

      await ax.patch('/web_page/set_data_model/', payload); // Ensure the endpoint supports bulk updates

      toast.success(`Data model set successfully for the web page and its children.`);

      // Find the selected data model
      const selectedModel = dataModels.find((model) => model.id === page.data_model_id) || null;
      const selectedModelName = selectedModel ? selectedModel.name : null;

      // Update the local state immutably
      setWebPages((prevWebPages) =>
        produce(prevWebPages, (draft) => {
          const updatePages = (pages: WebPageType[]) => {
            pages.forEach((page) => {
              if (page.id === page.id) {
                // Update the parent page
                page.data_model_id = page.data_model_id;
                page.data_model_name = selectedModelName;
                // Recursively update all children
                page.children.forEach((child) => updateDataModelForAllChildren(child, page.data_model_id, selectedModelName));
              }
              if (page.children.length > 0) {
                updatePages(page.children);
              }
            });
          };
          updatePages(draft);
        })
      );
    } catch (error) {
      toast.error('Error setting data model for web pages');
      console.error('Error in handleBatchSetDataModel:', error);
    }
  };

  // Helper function to find a page by its unique key
  const findPageByKey = (
    pages: WebPageType[],
    key: string,
    parentKey: string = ''
  ): WebPageType | null => {
    for (const page of pages) {
      const currentKey = generateUniqueKey(page, parentKey);
      if (currentKey === key) {
        return page;
      }
      if (page.children.length > 0) {
        const found = findPageByKey(page.children, key, currentKey);
        if (found) return found;
      }
    }
    return null;
  };

  useEffect(() => {
    const fetchWebPages = async () => {
      try {
        const response = await ax.get('/web_page/all/list/');
        console.log('Fetched Web Pages:', response.data);
        setWebPages(response.data);
        // Initialize openGroups with all groups collapsed
        const initializeOpenGroups = (pages: WebPageType[], parentKey: string = '') => {
          pages.forEach((page) => {
            const key = generateUniqueKey(page, parentKey);
            if (page.children.length > 0) {
              setOpenGroups((prev) => ({ ...prev, [key]: false }));
              initializeOpenGroups(page.children, key);
            }
          });
        };
        initializeOpenGroups(response.data);
      } catch (error) {
        toast.error('Error fetching web pages');
        console.error('Error fetching web pages:', error);
      }
    };

    const fetchDataModels = async () => {
      try {
        const response = await ax.get('/data_models/all/');
        setDataModels(response.data);
        console.log('Fetched Data Models:', response.data);
      } catch (error) {
        toast.error('Error fetching data models');
        console.error('Error fetching data models:', error);
      }
    };

    const fetchConfigs = async () => {
      try {
        const response = await ax.get('/web_scrape_config/all/new');
        setConfigs(response.data);
      } catch (error) {
        toast.error('Failed to fetch web scrape configs.');
      }
    };

    fetchWebPages();
    fetchDataModels();
    fetchConfigs();
  }, []);

  // Added useEffect to log updated webPages
  useEffect(() => {
    console.log('Updated WebPages:', webPages);
  }, [webPages]);

  const handleEditOpen = (page: WebPageType) => {
    setCurrentEditPage(page);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setCurrentEditPage(null);
  };

  const handleViewDataOpen = (page: WebPageType) => {
    setViewDataContent(page);
    setViewDataOpen(true);
  };

  const handleViewDataClose = () => {
    setViewDataContent(null);
    setViewDataOpen(false);
  };

  const handleConfigChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedConfig(event.target.value);
  };

  const openEditModal = () => setIsEditModalOpen(true);
  const closeEditModal = () => setIsEditModalOpen(false);

  const renderCustomData = (customDataIds: string[]) => {
    if (!customDataIds || customDataIds.length === 0) {
      return <Typography>No Custom Data</Typography>;
    }

    return (
      <div>
        {customDataIds.map((id) => (
          <LazyDataComponent
            key={id}
            objId={id}
            fetchUrl="/custom_data" // Update with actual endpoint
            renderContent={(data: CustomData) => (
              <div style={{ marginBottom: '10px' }}>
                {Object.entries(data.obj).map(([key, value]) => (
                  value !== null && (
                    <Typography key={key}>
                      <strong>{key}:</strong> {String(value)}
                    </Typography>
                  )
                ))}
              </div>
            )}
          />
        ))}
      </div>
    );
  };

  const renderAdditionalDataTypes = (page: WebPageType) => {
    console.log('Rendering additional data for page:', page); // Debugging line
    const dataTypes = [];
  
    // Handle Custom Data
    if (page.custom_data && page.custom_data.length > 0) {
      dataTypes.push({
        type: 'Custom Data',
        content: renderCustomData(page.custom_data),
      });
    }
  
    // Handle Product Data
    if (page.product && page.product.length > 0) {
      console.log('Rendering Product Data for IDs:', page.product); // Debugging line
      dataTypes.push({
        type: 'Product',
        content: (
          <div>
            {page.product.map((productId) => (
              <LazyDataComponent
                key={productId}
                objId={productId}
                fetchUrl="/product" // Verify this endpoint
                renderContent={(data: Product) => (
                  <ProductComponent product={data} />
                )}
              />
            ))}
          </div>
        ),
      });
    }
  
    // Handle FAQ Data
    if (page.faq && page.faq.length > 0) {
      console.log('Rendering FAQ Data for IDs:', page.faq); // Debugging line
      dataTypes.push({
        type: 'FAQ',
        content: (
          <div>
            {page.faq.map((faqId) => (
              <LazyDataComponent
                key={faqId}
                objId={faqId}
                fetchUrl="/faq" // Verify this endpoint
                renderContent={(data: FAQ) => (
                  <FAQComponent faq={data} />
                )}
              />
            ))}
          </div>
        ),
      });
    }
  
    // Handle Web Data
    if (page.web && page.web.length > 0) {
      console.log('Rendering Web Data for IDs:', page.web); // Debugging line
      dataTypes.push({
        type: 'Web',
        content: (
          <div>
            {page.web.map((webId) => (
              <LazyDataComponent
                key={webId}
                objId={webId}
                fetchUrl="/web" // Verify this endpoint
                renderContent={(data: Web) => (
                  <WebComponent web={data} />
                )}
              />
            ))}
          </div>
        ),
      });
    }
  
    if (dataTypes.length === 0) {
      return <Typography>No Data Available</Typography>;
    }
  
    return (
      <div>
        {dataTypes.map((dataType, index) => (
          <div key={index} style={{ marginBottom: '20px' }}>
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
              {dataType.type}
            </Typography>
            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
              {dataType.content}
            </div>
            {index > 0 && <hr style={{ margin: '20px 0' }} />}
          </div>
        ))}
      </div>
    );
  };

  const renderWebPage = (page: WebPageType, parentKey: string = '', level: number = 0) => {
    const key = generateUniqueKey(page, parentKey);
    const isGroup = page.children.length > 0;
    const isExpanded = openGroups[key] || false;
    const displayChildren = isGroup && !isExpanded ? page.children.slice(0, 2) : page.children;
    const remainingChildrenCount = isGroup ? page.children.length - 2 : 0;

    // Format the date
    const formattedDate = page.last_refreshed_at
      ? new Date(page.last_refreshed_at).toLocaleDateString()
      : 'N/A';

    const fullTimestamp = page.last_refreshed_at
      ? new Date(page.last_refreshed_at).toLocaleString('default', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        })
      : '';

    return (
      <div
        key={key}
        style={{
          padding: '10px',
          borderBottom: '1px solid #eee',
          marginBottom: '5px',
          paddingLeft: `${level * 20}px`,
          backgroundColor: isGroup ? '#f9f9f9' : 'transparent',
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
          }}
        >
          {/* Left Section: URL and Last Refreshed */}
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <strong>{page.url}</strong>
              {isGroup && (
                <span style={{ marginLeft: '10px', color: '#666' }}>
                  ({page.children.length} {page.children.length === 1 ? 'page' : 'pages'})
                </span>
              )}
              {/* Expand/Collapse Button */}
              {isGroup && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleGroup(key);
                  }}
                  style={{ marginLeft: '10px' }}
                  aria-label={isExpanded ? 'Collapse group' : 'Expand group'}
                >
                  {isExpanded ? '▲' : '▼'}
                </button>
              )}
            </div>
            {page.last_refreshed_at && (
              <Typography
                variant="body2"
                style={{ color: '#888', marginTop: '4px' }}
                title={fullTimestamp}
              >
                Last Refreshed: {formattedDate}
              </Typography>
            )}
          </div>

          {/* Right Section: Data Model Dropdown and Action Buttons */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormControl variant="outlined" size="small" style={{ minWidth: '150px' }}>
              <InputLabel id={`data-model-label-${key}`}>Data Model</InputLabel>
              <Select
                labelId={`data-model-label-${key}`}
                label="Data Model"
                value={page.data_model_id || ''}
                onChange={(e) => handleDataModelChange(key, e.target.value)}
                renderValue={(selected) => {
                  if (selected === '') {
                    return <em>No Data Model</em>;
                  }
                  const selectedModel = dataModels.find((model) => model.id === selected);
                  return selectedModel ? selectedModel.name : 'Unknown';
                }}
              >
                <MenuItem value="">
                  <em>No Data Model</em>
                </MenuItem>
                {dataModels.map((model) => (
                  <MenuItem key={model.id} value={model.id}>
                    {model.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                if (page.data_model_id) {
                  if (isGroup) {
                    console.log('Saving data model for group:', key);
                    handleBatchSetDataModel(key);
                  } else {
                    console.log('Saving data model for single page:', key);
                    handleSaveDataModel(key);
                  }
                } else {
                  toast.error('Please select a data model before saving.');
                }
              }}
              disabled={!unsavedChanges[key]}
              style={{ marginLeft: '10px' }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleViewDataOpen(page);
              }}
              style={{ marginLeft: '10px' }}
            >
              View Data
            </Button>
            <IconButton
              aria-label="open in new tab"
              onClick={(e) => {
                e.stopPropagation();
                window.open(`https://${page.url}`, '_blank', 'noopener,noreferrer');
              }}
              style={{ marginLeft: '8px' }}
            >
              <OpenInNewIcon />
            </IconButton>
          </div>
        </div>

        {/* Collapsible Children */}
        {isGroup && (
          <Collapse isOpened={isExpanded}>
            <div style={{ paddingLeft: '20px' }}>
              {displayChildren.map((child) => renderWebPage(child, key, level + 1))}
              {!isExpanded && remainingChildrenCount > 0 && (
                <div
                  style={{ opacity: 0.5, cursor: 'pointer', marginTop: '5px' }}
                  onClick={() => toggleGroup(key)}
                >
                  <em>...and {remainingChildrenCount} more</em>
                </div>
              )}
            </div>
          </Collapse>
        )}
      </div>
    );
  };

  return (
    <div>
      <AdminCategoryTitle>Sidor som har hämtats</AdminCategoryTitle>
      <select onChange={handleConfigChange} value={selectedConfig || ''}>
        <option value="">Alla webbplatser</option>
        {configs.map(config => (
          <option key={config.id} value={config.id}>{config.name}</option>
        ))}
      </select>

      {isEditModalOpen && selectedConfig && (
        <PopupOverlay>
          <AdminPopupWindow>
            <button onClick={closeEditModal}>Close</button>
            {/* Add form to edit the selected config here */}
          </AdminPopupWindow>
        </PopupOverlay>
      )}

      {/* Edit Modal */}
      <Modal open={editOpen} onClose={handleEditClose}>
        <Box
          sx={{
            p: 4,
            bgcolor: 'background.paper',
            borderRadius: 1,
            maxWidth: '600px',
            width: '90%',
            margin: 'auto',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
          }}
        >
          <Typography variant="h6">Edit Web Page</Typography>
          {currentEditPage && (
            <div>
              <p>
                <strong>URL:</strong> {currentEditPage.url}
              </p>
              <p>
                <strong>Data Model:</strong> {currentEditPage.data_model_name || 'No Data Model'}
              </p>
              {/* Add form elements for editing here */}
              <Button onClick={handleEditClose} variant="contained" color="secondary">
                Close
              </Button>
            </div>
          )}
        </Box>
      </Modal>
      {/* View Data Modal */}
      <Modal open={viewDataOpen} onClose={handleViewDataClose}>
        <Box
          sx={{
            p: 4,
            bgcolor: 'background.paper',
            borderRadius: 1,
            maxWidth: '800px',
            width: '95%',
            margin: 'auto',
            maxHeight: '80vh',
            overflowY: 'auto',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
          }}
        >
          {viewDataContent ? (
            <div>
              <Typography variant="h6" style={{ marginBottom: '10px' }}>
                <a href={`https://${viewDataContent.url}`} target="_blank" rel="noopener noreferrer">
                  {viewDataContent.url}
                </a>
              </Typography>
              <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginBottom: '10px' }}>
                Data Model: {viewDataContent.data_model_name || 'N/A'}
              </Typography>
              {renderAdditionalDataTypes(viewDataContent)}
              <Button
                onClick={handleViewDataClose}
                variant="contained"
                color="secondary"
                style={{ marginTop: '20px' }}
              >
                Close
              </Button>
            </div>
          ) : (
            <Typography>No Data Available</Typography>
          )}
        </Box>
      </Modal>
      {/* Render Web Pages */}
      {webPages.map((page) => renderWebPage(page))}
    </div>
  );
};

export default WebPageList;