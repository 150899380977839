import { Breadcrumbs as MuiBreadcrumbs, Link as MuiLink, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

interface BreadCrumbsProps {
  campaignName?: string;
  variantName?: string;
  contentName?: string;
}

const BreadCrumbs = ({ campaignName, variantName, contentName }: BreadCrumbsProps) => {
  const navigate = useNavigate();
  const { campaignId, variantId, contentId } = useParams<{ campaignId: string; variantId: string; contentId: string }>();

  return (
    <MuiBreadcrumbs aria-label="breadcrumb" sx={{ mb: 1, cursor: 'pointer' }}>
      <MuiLink color="inherit" onClick={() => navigate('/marketing')}>
        Marketing
      </MuiLink>
      {campaignId && (
        <MuiLink color="inherit" onClick={() => navigate(`/marketing/${campaignId}`)}>
          {campaignName || 'Campaign'}
        </MuiLink>
      )}
      {variantId && (
        <MuiLink color="inherit" onClick={() => navigate(`/marketing/${campaignId}/${variantId}`)}>
          {variantName || 'Variant'}
        </MuiLink>
      )}
      {contentId && (
        <Typography color="textPrimary">{contentName || 'Content'}</Typography>
      )}
    </MuiBreadcrumbs>
  );
};

export default BreadCrumbs; 