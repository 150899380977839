import React from 'react';

import { 
  AISection,
  AssistantHeader,
  ConversationSection, 
  CopilotChatItem, 
  ChatProfileImgContainer, 
  CopilotProfileImg, 
  CopilotChatItemText, 
  CopilotInputSection, 
  CopilotInput,
  SendIconButton, 
} from '../../MarketingPage/MarketingPage-styling';
import { CopilotImgAnimation } from '../../UserPage/UserPage-styling';
import MarkdownRenderer from '../../Components/MarkdownRenderer';
import { useCurrentUser } from '../../Queries/useCurrentUser';
import { FaPaperPlane } from 'react-icons/fa6';
import { useAssistantConfigForModule } from '../../Queries/useAssistantConfigForModule';
import { AssistantSection } from '../MarketingPageNew-styling';



type Props = {
  inputText: string;
  module: string;
  handleTextareaChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleEnterSend: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  conversationRef: React.RefObject<HTMLDivElement>;
  handleSend: () => void;
  copilotChat: string[];
  answerLoading: boolean[];
};

const Assistant = (props: Props) => {
  const { data: currentUser } = useCurrentUser();
  const { data: assistantConfig, ...assistantConfigQuery } = useAssistantConfigForModule(props.module);

  return (
    <AssistantSection>
      <AssistantHeader>AI-Assistenten</AssistantHeader>
      <AISection>
        <ConversationSection ref={props.conversationRef} >
          {props.copilotChat.map((item, index) => {
            if (index % 2 === 0) { // Check if index is even
              return (
                <CopilotChatItem key={index} $isCopilot={true} >
                  {props.answerLoading[index/2] &&
                    <CopilotImgAnimation style={{height: '48px', width: '48px', top: '28px', left: '28px'}} />
                  }
                <ChatProfileImgContainer $isCopilot={true} >
                  {assistantConfig?.logo_url ? (
                    <CopilotProfileImg src={assistantConfig.logo_url} alt="AI"/>
                  ) : (
                    "AI"
                  )}
                </ChatProfileImgContainer>
                {props.answerLoading[index/2] &&
                  <MarkdownRenderer markdownText={item} isStreaming={true} fontSize={16} />
                }
                {!props.answerLoading[index/2] &&
                  <MarkdownRenderer markdownText={item} isStreaming={false} fontSize={16} />
                }
              </CopilotChatItem>
            )
          } else
            return (
              <CopilotChatItem key={index} $isCopilot={false} >
                <ChatProfileImgContainer $isCopilot={false} >
                  {currentUser?.first_name.charAt(0)}
                </ChatProfileImgContainer>
                <CopilotChatItemText>{item}</CopilotChatItemText>
              </CopilotChatItem>
            )
          })}
        </ConversationSection>
        <CopilotInputSection>
          <SendIconButton onClick={props.handleSend}>
            <FaPaperPlane />
          </SendIconButton>
          <CopilotInput
            id='assistantInputId'
            value={props.inputText}
            onChange={props.handleTextareaChange}
            placeholder="Skriv din fråga här..."
            rows={1}
            onKeyDown={props.handleEnterSend}
          />
        </CopilotInputSection>

      </AISection>
    </AssistantSection>
  );
};
export default Assistant;
