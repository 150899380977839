import { useQueryClient } from "@tanstack/react-query";
import { ax } from "../Utils";
import { useMutation } from "@tanstack/react-query";
import { Campaign, CampaignInfo } from "../Types";


export const useMarketingCampaignMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (requestBody: any) => {
      const result = requestBody.id ?
        ax.patch(`/marketing_campaign`, requestBody)
        : ax.post(`/marketing_campaign`, requestBody)
      return result;
    },
    onSuccess: (response, variables) => {
      queryClient.setQueryData(['marketing_campaign', { obj_id: response.data.id }], response.data);

      if (variables.id) {
        queryClient.setQueryData(['marketing_campaign', 'all', 'list'], (oldData: Campaign[]) => {
          return oldData.map((campaign: Campaign) =>
            campaign.id === response.data.id ? { ...campaign, ...response.data } : campaign
          )
        });
      } else {
        queryClient.setQueryData(['marketing_campaign', 'all', 'list'], (oldData: Campaign[]) => {
          return [...oldData, response.data]
        });
      }
    },
  });

  return mutation;
}