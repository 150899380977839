import { useQuery } from "@tanstack/react-query";
import { ax } from "../Utils";
import { Variant } from "../Types";



export const useCampaignVariant = (obj_id: string | undefined) => {
  return useQuery<Variant, Error>({
    queryKey: ['marketing_campaign_variant', { obj_id }],
    queryFn: (): Promise<Variant> => ax.get(`/marketing_campaign_variant?obj_id=${obj_id}`).then(res => res.data),
    enabled: !!obj_id,
  });
}