import { ax } from "../Utils";
import { useQuery } from "@tanstack/react-query";
import { Campaign } from "../Types";



export const useCampaignList = () => {
  return useQuery<Campaign[], Error>({
    queryKey: ["marketing_campaign", "all", "list"],
    queryFn: (): Promise<Campaign[]> => ax.get('/marketing_campaign/all/list/').then(res => res.data),
    refetchOnReconnect: "always",
    refetchInterval: 1000 * 60 * 3, //hur ofta behöver marketinglistan uppdateras?
  });
};