import { useMemo, useState } from "react";
import { Template } from "../../../../Types";
import { ColumnDef } from "@tanstack/react-table";
import { useTemplates } from "../../../../Queries/useTemplates";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Button } from "@mui/material";
import Swal from "sweetalert2";
import { queryClient } from "../../../../lib/queryClient";
import { ax } from "../../../../Utils";
import Table from "../../Table";
import { AdminButton, AdminCategoryTitle } from "../../../AdminPage-styling";
import EditTemplatePopup from "./EditTemplatePopup";
import { useTemplateMutation } from "../../../../Mutations/TemplateMutation";
import { toast } from "react-toastify";


const TemplatesComponent = () => {

  const [isEditTemplatePopupOpen, setIsEditTemplatePopupOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);

  const { data: templates, isLoading, error } = useTemplates();
  
  const templateMutation = useTemplateMutation();
  
  const handleEditTemplate = (template: Template) => {
    setSelectedTemplate(template);
    setIsEditTemplatePopupOpen(true);
  };

  const handleClickDeleteTemplate = (templateId: string) => {
    Swal.fire({
      title: 'Vill du radera denna mall?',
      text: 'Du kommer inte kunna återställa mallen som har raderats!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ja, radera den!',
      confirmButtonColor: '#d33',
      cancelButtonText: 'Avbryt'
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteTemplate(templateId);
      }
    });
  }

  const handleDeleteTemplate = (templateId: string) => {
    ax.delete(`/template/${templateId}`).then(() => {
      queryClient.setQueryData(['template', 'all'], (oldData: Template[]) => {
        return oldData.filter(template => template.id !== templateId);
      });
    });
  };

  const handleAddTemplate = () => {
    const newTemplate: Template = {
      id: '',
      title: '',
      text: '',
      last_updated_at: '',
      created_at: '',
    };
    setSelectedTemplate(newTemplate);
    setIsEditTemplatePopupOpen(true);
  };

  const handleSaveTemplate = (template: Template) => {
    try {
      templateMutation.mutate(template);
      setIsEditTemplatePopupOpen(false);
      setSelectedTemplate(null);
    } catch (error) {
      toast.error('Något gick fel, försök igen senare!');
    }
  };

  const columns = useMemo<ColumnDef<Template, any>[]>(
    () => [
      {
        accessorKey: 'title',
        header: 'Title',
        cell: (info) => info.getValue(),
      },
      {
        accessorKey: 'text',
        header: 'Text',
        cell: (info) => (
          <div style={{
            maxHeight: '2.4em',
            lineHeight: '1.2',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            whiteSpace: 'normal'
          }}>
            {info.getValue()}
          </div>
        ),
      },
      {
        accessorKey: 'last_updated_at',
        header: 'Last Updated',
        cell: (info) => new Date(info.getValue()).toLocaleString(),
      },
      {
        accessorKey: 'edit',
        header: 'Edit',
        cell: (info) => (
          <Button
            onClick={() => handleEditTemplate(info.row.original)}
            sx={{
              color: '#f0f0f0',
              '&:hover': {
                color: '#ccc',
              }
            }}
          >
            <FaEdit size={20} />
          </Button>
        ),
      },
      {
        accessorKey: 'delete',
        header: 'Delete',
        cell: (info) => (
          <Button 
            onClick={() => handleClickDeleteTemplate(info.row.original.id)}
            sx={{
              color: '#f0f0f0',
              '&:hover': {
                color: '#d33',
              }
            }}
          >
            <FaTrash size={20} />
          </Button>
        ),
      }
    ],
    [templates],
  );
  
  return (
    <>
      <AdminCategoryTitle>Templates</AdminCategoryTitle>
      <Table columns={columns} data={templates || []} columnWidths={{
        first: '17.5%',
        last: '5%',
        secondToLast: '5%',
        middle: '20%',
        second: '50%',
      }} />
      <AdminButton onClick={handleAddTemplate}>Add New Template</AdminButton>
      {isEditTemplatePopupOpen && selectedTemplate &&
        <EditTemplatePopup 
          onClose={() => {
            setIsEditTemplatePopupOpen(false);
            setSelectedTemplate(null);
          }} 
          onSave={handleSaveTemplate}
          selectedTemplate={selectedTemplate}
        />
      }
    </>
  );
};

export default TemplatesComponent;