import React, { useRef, useState, useEffect } from 'react';
import { Box } from '@mui/material';
import Sidebar from './Sections/Sidebar';
import CampaignDetails from './Sections/CampaignDetails';
import VariantDetails from './Sections/VariantDetails';
import ContentDetails from './Sections/ContentDetails';
import Assistant from './Sections/Assistant';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { StandardBase } from '../Components/StandardBase';
import { Campaign } from '../Types';
import BreadCrumbs from './Sections/BreadCrumbs';
import { ax } from '../Utils';
import { useCampaignList } from '../Queries/useCampaignList';
import { useSelectedCampaignVariant } from '../Queries/useSelectedCampaignVariant';
import { useMarketingContent } from '../Queries/useMarketingContent';
import { StickyAssistantContainer, StyledMarketingPage } from './MarketingPageNew-styling';
import { useAnswerLoading } from '../Queries/useAnswerLoading';
import { useAssistantChat } from '../Queries/useAssistantChat';
import { Editor } from '@tiptap/react';
import { useContentMarketing } from '../Queries/useContentMarketing';



const MarketingPageNew = () => {
  const { campaignId, variantId, contentId } = useParams<{ campaignId?: string; variantId?: string; contentId?: string }>();
 
  const conversationRef = useRef<HTMLDivElement>(null);
  const editorRef = useRef<Editor | null>(null);

  const module = 'marketing';

  const [inputText, setInputText] = useState('');


  const { data: campaigns } = useCampaignList();
  const { assistantChat, generateAnswer, streamWelcomeMessage, addMessage, clearChat } = useAssistantChat(module, contentId ?? variantId ?? campaignId, contentId ? 'content' : variantId ? 'variant' : campaignId ? 'campaign' : null); 
  const { data: answerLoading } = useAnswerLoading(module, contentId ?? variantId ?? campaignId);
  const { data: editorContent } = useContentMarketing(module, contentId ?? variantId ?? campaignId, contentId ? 'content' : variantId ? 'variant' : campaignId ? 'campaign' : null);

  // const { selectedCampaignVariant, updateSelectedCampaignVariant } = useSelectedCampaignVariant();

  const currentCampaign = campaigns?.find(campaign => campaign.id === campaignId) ?? null;
  const currentVariant = currentCampaign?.variants?.find(variant => variant.id === variantId) ?? null;
  // const currentContent = currentVariant?.contents?.find(content => content.id === contentId) ?? null;

  const { data: currentContent, isLoading: isContentLoading } = useMarketingContent(contentId);

  useEffect(() => {
    if (assistantChat.length === 1 && assistantChat[0] === '' && !isContentLoading) {
      streamWelcomeMessage(currentContent ?? currentVariant ?? currentCampaign ?? null);
    }
  }, [campaignId, variantId, contentId, currentContent, isContentLoading, assistantChat]);

  useEffect(() => {
    scrollToBottom();
  }, [assistantChat]);

  useEffect(() => {
    if (inputText === '') {
      const textarea = document.getElementById('assistantInputId');
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = (textarea.scrollHeight - 30 > 100 ? 100 : textarea.scrollHeight - 30) + 'px';
      }
    }
  }, [inputText]);

  const scrollToBottom = () => {
    if (conversationRef.current && assistantChat.length !== 0) {
      const lastMessage = conversationRef.current.lastChild as HTMLElement;
      lastMessage.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleTextareaChange = (e: { target: { value: string; style: { height: string; }; scrollHeight: number; }; }) => {
    setInputText(e.target.value);
    //updateAssistantInputText(e.target.value);
    e.target.style.height = 'auto';
    e.target.style.height = (e.target.scrollHeight - 30 > 100 ? 100 : e.target.scrollHeight - 30) + 'px';
  };

  const handleSend = () => {
    if (inputText === ''){
      return;
    }
    addMessage(inputText);
    generateAnswer({
      text: inputText, 
      module_name: module, 
      current_content: editorRef.current?.getHTML() || '', 
      module_ref_id: contentId ?? variantId ?? campaignId ?? null, 
      module_level: contentId ? 'content' : variantId ? 'variant' : campaignId ? 'campaign' : null
    }).then((gotContent) => {
      // if (gotContent) {
      //   handleChange(editorRef.current?.getHTML() || '', editorRef.current?.getText() || '');
      // }
    });
    setInputText('');
    //updateAssistantInputText('');
  };

  const handleEnterSend = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };
  
  const renderContent = () => {
    if (contentId) {
      return (
        <ContentDetails 
          editorContent={editorContent} 
          editorRef={editorRef} 
        />
      );
    }
    if (variantId) {
      return (
        <VariantDetails 
          editorContent={editorContent} 
          editorRef={editorRef} 
        />
      );
    }
    if (campaignId) return <CampaignDetails />;
    return <div>Select a campaign to view details</div>;
  };


  return (
    <StandardBase>
      <StyledMarketingPage>
        <Sidebar isCollapsed={false} onToggleCollapse={() => {}} />
        <Box flex="2" className="content" marginLeft="10px" marginRight="5px" display="flex" flexDirection="column">
          <BreadCrumbs 
            campaignName={currentCampaign?.name} 
            variantName={currentVariant?.name} 
            contentName={currentContent?.content_title || '*unnamed*'} 
          />
          {renderContent()}
        </Box>
        {/* <Box flex="1" marginLeft="0"></Box> */}
        <StickyAssistantContainer>
          <Assistant
            inputText={inputText}
            module={module}
            handleTextareaChange={handleTextareaChange}
            handleEnterSend={handleEnterSend}
            conversationRef={conversationRef}
            handleSend={handleSend}
            copilotChat={assistantChat}
            answerLoading={answerLoading}
          />
        </StickyAssistantContainer>
      </StyledMarketingPage>
    </StandardBase>
  );
};

export default MarketingPageNew; 