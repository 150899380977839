import React, { useState, useEffect } from 'react';
import { ax } from '../../../../Utils';
import { toast } from 'react-toastify';
import { AdminButton, AdminPopupWindow, AdminSettingContainer, AdminSettingForm, AdminPopupText, AdminPopupInput, AdminPopupSelectBox, AdminPopupCheckbox } from '../../../AdminPage-styling';
import { CustomDataModel, Attribute, AttributeType } from './CustomDataModelsTable';
import { PopupOverlay, CloseButton } from '../../../../ErrandsPage/ErrandsPage-styling';
import { useQuery } from '@tanstack/react-query';

interface EditSchemaPopupProps {
  modelId: string;
  initialSchema: CustomDataModel;
  onClose: () => void;
  onSave: (updatedSchema: CustomDataModel) => void;
}

const EditSchemaPopup: React.FC<EditSchemaPopupProps> = ({ modelId, initialSchema, onClose, onSave }) => {
  const [attributes, setAttributes] = useState<Attribute[]>([]);
  const [modelName, setModelName] = useState(initialSchema.name || '');
  const [modelDescription, setModelDescription] = useState(initialSchema.description || '');

  const { data: availableTypes, isLoading, error } = useQuery<AttributeType[]>({
    queryKey: ['attribute_types'],
    queryFn: async () => {
      const response = await ax.get('/custom_data_model/attribute_types');
      return response.data;
    },
  });

  useEffect(() => {
    setModelName(initialSchema.name || '');
    setModelDescription(initialSchema.description || '');
    setAttributes(Object.entries(initialSchema.object_schema || {}).map(([name, details]) => {
      try {
        const detail = typeof details === 'string' ? JSON.parse(details as unknown as string) as Attribute : details; // Parse JSON string if needed
        return {
          name,
          type: detail.type,
          description: detail.description,
          required: detail.required,
        };
      } catch (error) {
        console.error('Error parsing attribute details:', error);
        return {
          name,
          type: '',
          description: '',
          required: false,
        };
      }
    }));
  }, [initialSchema]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading attribute types.</div>;

  const handleAddAttribute = () => {
    setAttributes([...attributes, { name: '', type: '', description: '', required: false }]);
  };

  const handleDeleteAttribute = (index: number) => {
    setAttributes(attributes.filter((_, i) => i !== index));
  };

  const handleSave = async () => {
    const hasIncompleteRows = attributes.some(attr => !attr.name || !attr.description);
    if (hasIncompleteRows) {
      toast.error('Please fill in all required fields for each attribute.');
      return;
    }

    const updatedSchema = attributes.reduce((acc, attr) => {
      acc[attr.name] = {
        name: attr.name,
        type: attr.type,
        description: attr.description,
        required: attr.required,
      };
      return acc;
    }, {} as Record<string, Attribute>);

    const modelData: CustomDataModel = {
      id: modelId,
      name: modelName,
      description: modelDescription,
      object_schema: updatedSchema,
    };

    try {
      if (!modelId) {
        const response = await ax.post('/custom_data_model', modelData);
        modelData.id = response.data.id;
        toast.success('Custom data model created successfully!');
      } else {
        await ax.patch('/custom_data_model', modelData);
        toast.success('Custom data model updated successfully!');
      }
      onSave(modelData);
    } catch (error) {
      toast.error('Failed to save custom data model.');
    }
    onClose();
  };

  return (
    <PopupOverlay>
      <AdminPopupWindow style={{ width: '80%', maxWidth: '1000px' }}>
        <CloseButton onClick={onClose} />
        <AdminSettingForm onSubmit={(e: React.FormEvent<HTMLFormElement>) => { e.preventDefault(); handleSave(); }}>
          <AdminPopupText>Edit Data Model</AdminPopupText>
          <AdminSettingContainer>
            <AdminPopupText>Name</AdminPopupText>
            <AdminPopupInput type="text" value={modelName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setModelName(e.target.value)} />
          </AdminSettingContainer>
          <AdminSettingContainer>
            <AdminPopupText>Description</AdminPopupText>
            <AdminPopupInput type="text" value={modelDescription} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setModelDescription(e.target.value)} />
          </AdminSettingContainer>
          <AdminPopupText>Schema</AdminPopupText>
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Description</th>
                <th>Required</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {attributes.map((attr, index) => (
                <tr key={index}>
                  <td><AdminPopupInput type="text" value={attr.name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const newAttributes = [...attributes];
                    newAttributes[index].name = e.target.value;
                    setAttributes(newAttributes);
                  }} /></td>
                  <td>
                    <AdminPopupSelectBox value={attr.type} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      const newAttributes = [...attributes];
                      newAttributes[index].type = e.target.value;
                      setAttributes(newAttributes);
                    }}>
                      {availableTypes?.map((type) => <option key={type.name} value={type.name}>{type.name}</option>)}
                    </AdminPopupSelectBox>
                  </td>
                  <td><AdminPopupInput type="text" value={attr.description} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const newAttributes = [...attributes];
                    newAttributes[index].description = e.target.value;
                    setAttributes(newAttributes);
                  }} /></td>
                  <td><AdminPopupCheckbox type="checkbox" checked={attr.required} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const newAttributes = [...attributes];
                    newAttributes[index].required = e.target.checked;
                    setAttributes(newAttributes);
                  }} /></td>
                  <td><AdminButton onClick={() => handleDeleteAttribute(index)}>Delete</AdminButton></td>
                </tr>
              ))}
            </tbody>
          </table>
          <AdminButton onClick={handleAddAttribute}>Add Attribute</AdminButton>
          <AdminButton type="submit">Save data model</AdminButton>
        </AdminSettingForm>
      </AdminPopupWindow>
    </PopupOverlay>
  );
};

export default EditSchemaPopup; 