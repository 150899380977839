import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AdminButton, AdminCategoryTitle } from '../../../AdminPage-styling';
import { ax } from '../../../../Utils';
import Table from '../../../AdminComponents/Table';
import { toast } from 'react-toastify';
import { ColumnDef, CellContext } from '@tanstack/react-table';
import Swal from 'sweetalert2';

interface FileData {
  id: string;
  title: string;
  description: string;
  can_be_used_by_external_chatbot: boolean;
  file_type: string;
  file_name: string;
}

const FilesTable = () => {
  const [files, setFiles] = useState<FileData[]>([]);
  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    try {
      const response = await ax.get('/file/all/');
      setFiles(response.data);
    } catch (error) {
      toast.error('Failed to fetch files.');
    }
  };

  const onSubmit = async (data: any) => {
    try {
      const createResponse = await ax.post('/file', { title: data.title, description: data.description });
      const fileId = createResponse.data.id;
      const formData = new FormData();
      formData.append('file_upload', data.file[0]);
      await ax.post(`/file/upload_file/?file_id=${fileId}`, formData);
      toast.success('File uploaded successfully!');
      fetchFiles();
      reset();
    } catch (error) {
      toast.error('Failed to upload file.');
    }
  };

  const handleDownload = async (fileId: string, fileName: string) => {
    try {
      const response = await ax.get(`/file/download_file/?file_id=${fileId}`, { responseType: 'blob' });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      toast.error('Failed to download file.');
    }
  };

  const handleReupload = async (fileId: string, file: File) => {
    try {
      const formData = new FormData();
      formData.append('file_upload', file);
      await ax.post(`/file/upload_file/?file_id=${fileId}`, formData);
      toast.success('File re-uploaded successfully!');
      fetchFiles();
    } catch (error) {
      toast.error('Failed to re-upload file.');
    }
  };

  const handleUpdate = async (fileId: string, updatedData: Partial<FileData>) => {
    try {
      await ax.patch('/file', { id: fileId, ...updatedData });
      toast.success('File updated successfully!');
      fetchFiles();
    } catch (error) {
      toast.error('Failed to update file.');
    }
  };

  const openUploadPopup = (fileId: string) => {
    Swal.fire({
      title: 'Upload New File',
      input: 'file',
      inputAttributes: {
        accept: '*/*',
        'aria-label': 'Upload your file'
      },
      showCancelButton: true,
      confirmButtonText: 'Upload',
      preConfirm: (file) => {
        if (file) {
          handleReupload(fileId, file);
        }
      }
    });
  };

  const handleDelete = async (fileId: string) => {
    try {
      await ax.delete(`/file/${fileId}`);
      toast.success('File deleted successfully!');
      fetchFiles();
    } catch (error) {
      toast.error('Failed to delete file.');
    }
  };

  const addNewFileRow = () => {
    const newFile: FileData = {
      id: '',
      title: '',
      description: '',
      can_be_used_by_external_chatbot: false,
      file_type: '',
      file_name: '',
    };
    setFiles([...files, newFile]);
  };

  const columns: ColumnDef<FileData>[] = [
    {
      header: 'Title',
      accessorFn: (row) => row.title,
      cell: ({ getValue, row }: CellContext<FileData, unknown>) => (
        <input
          type="text"
          defaultValue={getValue() as string}
          onBlur={(e) => handleUpdate(row.original.id, { title: e.target.value })}
        />
      ),
    },
    {
      header: 'Description',
      accessorFn: (row) => row.description,
      cell: ({ getValue, row }: CellContext<FileData, unknown>) => (
        <input
          type="text"
          defaultValue={getValue() as string}
          onBlur={(e) => handleUpdate(row.original.id, { description: e.target.value })}
        />
      ),
    },
    {
      header: 'Filename',
      accessorFn: (row) => row.file_name,
    },
    {
      header: 'External Chatbot',
      accessorFn: (row) => row.can_be_used_by_external_chatbot,
      cell: ({ getValue, row }: CellContext<FileData, unknown>) => (
        <input
          type="checkbox"
          checked={getValue() as boolean || false}
          onChange={(e) => handleUpdate(row.original.id, { can_be_used_by_external_chatbot: e.target.checked })}
        />
      ),
    },
    {
      header: 'Actions',
      cell: ({ row }: { row: { original: FileData } }) => (
        <div>
          <AdminButton 
            onClick={() => handleDownload(row.original.id, row.original.file_name)}
            disabled={!row.original.file_name}
          >
            Download
          </AdminButton>
          <AdminButton onClick={() => openUploadPopup(row.original.id)}>
            {row.original.file_name ? 'Upload New File' : 'Upload File'}
          </AdminButton>
          <AdminButton onClick={() => handleDelete(row.original.id)}>Delete</AdminButton>
        </div>
      ),
    },
  ];

  return (
    <div>
      <AdminCategoryTitle>Files</AdminCategoryTitle>
      <Table columns={columns} data={files} />
      <AdminButton onClick={addNewFileRow}>Create New File</AdminButton>
    </div>
  );
};

export default FilesTable; 