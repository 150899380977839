import { useQueryClient } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { ax } from "../Utils";
import { Campaign, Variant } from "../Types";



export const useCampaignVariantMutation = () => {
  const queryClient = useQueryClient();

  const campaignVariantMutation = useMutation({
    mutationFn: async (requestBody: any) => {
      console.time('PATCH request');
      const result = requestBody.id 
        ? await ax.patch('/marketing_campaign_variant', requestBody)
        : await ax.post('/marketing_campaign_variant', requestBody);
      console.timeEnd('PATCH request');
      return result;
    },
    onSuccess: (response, variables) => {
      console.log("variables.id", variables.id);
      queryClient.setQueryData(['marketing_campaign_variant', {obj_id: response.data.id}], response.data);

      if (variables.id) {
        queryClient.setQueryData(['marketing_campaign', 'all', 'list'], (oldData: Campaign[]) => {
          return oldData.map((campaign: Campaign) => {
            if (campaign.id === response.data.campaign_id) {
              return {
                ...campaign,
                variants: campaign.variants.map((variant: Variant) =>
                  variant.id === response.data.id ? response.data : variant
                )
              };
            }
            return campaign;
          });
        });
      } else {
        console.log("response.data", response.data);
        queryClient.setQueryData(['marketing_campaign', 'all', 'list'], (oldData: Campaign[]) =>  {
          return oldData.map((campaign) => 
            campaign.id === response.data.campaign_id 
              ? { ...campaign, variants: [...(campaign.variants || []), response.data] }
              : campaign
          )
        });
      }
      if (variables.base_content) {
        queryClient.setQueryData(['content', { module: 'marketing' }, { obj_id: variables.id }], response.data.base_content);
      }
    },
  });

  return campaignVariantMutation;
};