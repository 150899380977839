import styled from 'styled-components';
import { Typography } from '@mui/material';
export const MarketingSettingText = styled.p`
  color: #000;
  font-size: 1rem;
  margin-bottom: 0.3rem;
  text-align: start;
  margin-top: 0.2rem;
`;

export const CampaignCard = styled.div<{ $isSelected: boolean; $expanded: boolean }>`
  padding: 10px;
  margin-bottom: 5px;
  background-color: ${({ $isSelected }) => ($isSelected ? 'rgba(0, 0, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)')};
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid rgba(0, 0, 0, 0.3);
  width: ${({ $expanded }) => ($expanded ? 'auto' : '50px')};
  box-shadow: ${({ $isSelected }) => ($isSelected ? 'inset 0px 0px 3px 0px rgba(0, 0, 0, 0.4)' : 'none')};

  &:hover {
    background-color: ${({ $isSelected }) => ($isSelected ? 'rgba(0, 0, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)')};
  }
`;

export const TruncatedText = styled(Typography)<{ $isCollapsed: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${props => props.$isCollapsed ? '60px' : '200px'};
`;

export const AddButton = styled.button<{ $expanded: boolean }>`
  padding: 8px;
  margin-bottom: 5px;
  background-color: rgba(0, 39, 176, 0.1);
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 2px dashed rgba(0, 39, 176, 0.4);
  width: ${({ $expanded }) => ($expanded ? '100%' : '50px')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 39, 176, 0.8);
  font-size: 0.9rem;
  font-weight: 500;

  &:hover {
    background-color: rgba(0, 39, 176, 0.15);
    border-color: rgba(0, 39, 176, 0.6);
  }

  &:active {
    background-color: rgba(0, 39, 176, 0.2);
    transform: scale(0.95);
  }
`;

export const AssistantSection = styled.div`
  height: calc(100% - 18px);
  flex: 1;
  width: calc(100% - 16px);
  min-width: 210px;
  min-height: 320px;
  margin-left: 5px;
  background-color: rgba(0, 0, 0, 0.2);
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  display: flex;
  border: solid;
  border-color: #444;
  border-width: 5px;
  border-radius: 6px;
  align-self: safe start;
`;

export const StickyAssistantContainer = styled.div`
  position: sticky;
  top: 20px; // Add some spacing from the top
  height: calc(100vh - 138px); // Subtract top and bottom spacing
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const StyledMarketingPage = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  // min-height: calc(100vh - 100px);
  gap: 5px; // Add consistent spacing between elements
`;