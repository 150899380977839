import { useQuery } from "@tanstack/react-query";
import { ax } from "../Utils";
import { MarketingContent } from "../Types";



export const useMarketingContent = (obj_id: string | undefined) => {
  return useQuery<MarketingContent, Error>({
    queryKey: ['marketing_content', { obj_id }],
    queryFn: (): Promise<MarketingContent> => ax.get(`/marketing_content/with_meta/?obj_id=${obj_id}`).then(res => res.data),
    enabled: !!obj_id,
  });
};