import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { Typography } from '@mui/material';
import { ax } from '../../../../Utils';

type LazyDataComponentProps<T> = {
  objId: string;
  fetchUrl: string;
  renderContent: (data: T) => React.ReactNode;
};

const LazyDataComponent = <T extends {}>({
  objId,
  fetchUrl,
  renderContent,
}: LazyDataComponentProps<T>) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (inView && !data && !error) {
      ax.get(`${fetchUrl}?obj_id=${objId}`)
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => {
          console.error(`Error fetching data for ID ${objId}:`, err);
          setError('Failed to load data.');
        });
    }
  }, [inView, objId, fetchUrl, data, error]);

  return (
    <div ref={ref} style={{ minHeight: '50px', marginBottom: '10px' }}>
      {data ? (
        renderContent(data)
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <Typography>Loading...</Typography>
      )}
    </div>
  );
};

export default LazyDataComponent;