import { zodResolver } from "@hookform/resolvers/zod";
import { useAssistantLanguages } from "../../../Queries/useAssistantLanguages";
import { chatbotBasicInfoSchema, TChatbotBasicInfoSchema } from "../../../Types";
import { AdminButton, AdminInputError, AdminSettingContainer, AdminSettingInput, AdminSettingSelectBox, AdminSettingText, AdminSettingTextarea, ConfigSectionContainer, ConfigSectionTitle } from "../../AdminPage-styling";
import { useForm } from "react-hook-form";
import { AuthorizationError } from "../../../AuthorizationPages/Authorization-styling";
import { useSelectedChatbot } from "../../../Queries/useSelectedChatbot";
import { useChatbotConfigMutation } from "../../../Mutations/ChatbotConfigMutation";
import { useChatbotAssistantConfig } from "../../../Queries/useChatbotAssistantConfig";
import { useAssistantConfigMutation } from "../../../Mutations/AssistantConfigMutation";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useEffect } from "react";


const BasicInfoConfig = () => {

  const {data: assistantLanguages} = useAssistantLanguages();
  const {selectedChatbot, updateSelectedChatbot} = useSelectedChatbot();
  const {data: assistantConfig} = useChatbotAssistantConfig(selectedChatbot?.assistant_config_id);

  const chatbotConfigMutation = useChatbotConfigMutation();
  const assistantConfigMutation = useAssistantConfigMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
    reset,
  } = useForm<TChatbotBasicInfoSchema>({
    resolver: zodResolver(chatbotBasicInfoSchema),
  });

  useEffect(() => {
    if (!selectedChatbot && !isDirty) {
      reset({
        config_name: '',
        description: '',
        default_language_id: assistantLanguages?.[0]?.id || '',
        valid_domains: '',
      });
    } else if (assistantConfig && !isDirty) {
      reset({
        config_name: assistantConfig.config_name,
        description: assistantConfig.config_description,
        default_language_id: assistantConfig.default_assistant_language_id,
        valid_domains: selectedChatbot?.valid_domains?.[0],
      });
    }
  }, [assistantConfig, reset, isDirty, selectedChatbot, assistantLanguages]);

  useEffect(() => {
    if (selectedChatbot?.assistant_config_id) {
      reset({
        config_name: assistantConfig?.config_name,
        description: assistantConfig?.config_description,
        default_language_id: assistantConfig?.default_assistant_language_id,
        valid_domains: selectedChatbot?.valid_domains?.[0] || '',
      });
    }
    else {
      reset({
        config_name: '',
        description: '',
        default_language_id: assistantLanguages?.[0]?.id || '',
        valid_domains: '',
      });
    }
  }, [selectedChatbot?.assistant_config_id]);

  const onSubmit = async (data: TChatbotBasicInfoSchema) => {
    console.log(data);
    if (selectedChatbot?.id) {
      const requestBody = {
        id: selectedChatbot.id,
        config_name: data.config_name,
        valid_domains: [data.valid_domains],
      }
      try {
        chatbotConfigMutation.mutate(requestBody, {
          onSuccess: (response) => {
            updateSelectedChatbot(response.data);
            const assistantRequestBody = {
              id: response.data.assistant_config_id,
              config_name: data.config_name,
              config_description: data.description,
              default_assistant_language_id: data.default_language_id,
              for_external_chatbot: true,
            }
            assistantConfigMutation.mutate(assistantRequestBody, {
              onSuccess: () => {
                toast.success("Grundläggande info har sparats");
              }
            });
          }
        });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Något gick fel!',
          text: 'Försök igen senare.',
        })
      }
    }
    else {
      try {
        const requestBody = {
          config_name: data.config_name,
          config_description: data.description,
          default_assistant_language_id: data.default_language_id,
          for_external_chatbot: true,
        }
        assistantConfigMutation.mutate(requestBody, {
          onSuccess: (response) => {
            const chatbotRequestBody = {
              assistant_config_id: response.data.id,
              config_name: data.config_name,
              valid_domains: [data.valid_domains],
            }
            chatbotConfigMutation.mutate(chatbotRequestBody, {
              onSuccess: (response2) => {
                updateSelectedChatbot(response2.data);
                toast.success("Grundläggande info har sparats");
              }
            });
          }
        });
      }
      catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Något gick fel!',
          text: 'Försök igen senare.',
        })
      }
    }
  }

  return (
    <ConfigSectionContainer>
      <ConfigSectionTitle>Grundläggande info</ConfigSectionTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AdminSettingContainer>
          <AdminSettingText>Konfigurationsnamn</AdminSettingText>
          <AdminSettingInput
            type="text"
            placeholder="Namn"
            id="configName"
            {...register("config_name")}
          />
          {errors.config_name && 
            <AdminInputError>{errors.config_name.message}</AdminInputError>
          }
        </AdminSettingContainer>
        <AdminSettingContainer>
          <AdminSettingText>Beskrivning</AdminSettingText>
          <AdminSettingTextarea
            placeholder="Beskrivning"
            rows={3}
            {...register("description")}
          />
        </AdminSettingContainer>
        <AdminSettingContainer>
          <AdminSettingText>Standardspråk</AdminSettingText>
          <AdminSettingSelectBox
            id="defaultLanguage"
            {...register("default_language_id")}
          >
            {assistantLanguages?.map((language) => (
              <option key={language.id} value={language.id}>{language.name}</option>
            ))}
          </AdminSettingSelectBox>
        </AdminSettingContainer>
        <AdminSettingContainer>
          <AdminSettingText>Domän där chatboten ska användas</AdminSettingText>
          <AdminSettingInput
            type="text"
            placeholder="https://www.exempel.se"
            id="validDomains"
            {...register("valid_domains")}
          />
          {errors.valid_domains && 
            <AdminInputError>{errors.valid_domains.message}</AdminInputError>
          }
        </AdminSettingContainer>
        <AdminButton type="submit" disabled={isSubmitting}>Spara</AdminButton>
      </form>
    </ConfigSectionContainer>
  )
}

export default BasicInfoConfig;