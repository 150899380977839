
import { TAddInboxSchema, addInboxSchema } from "../../../Types";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ax } from "../../../Utils";
import { AdminButton, AdminInputError, AdminSettingContainer, AdminSettingForm, AdminSettingInput, AdminSettingText } from "../../AdminPage-styling";
import Swal from "sweetalert2";


export default function AddInbox() {

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<TAddInboxSchema>({
    resolver: zodResolver(addInboxSchema),
  });

  const onSubmit = async (data: TAddInboxSchema) => {
    const date_time = data.sync_from_date + 'T00:00:00.000Z';

    try {
      await ax.post(`/feedback_source/new/email/inbox/`, {
        name: data.inboxName,
        email: data.email,
        sync_from_date: date_time
      })
        .then((response) => {
          if (response.data) {
            console.log(response.data);
            Swal.fire({
              icon: 'info',
              title: 'Öppna länken för att autentisera inkorgen',
              text: response.data,
              confirmButtonText: 'Öppna länk',
              showCancelButton: true,
              cancelButtonText: 'Avbryt',
              preConfirm: () => {
                window.open(response.data, '_blank');
              },
            });
          }
        });
    }
    catch (error: any) {
      Swal.fire({
        icon: 'error',
        title: 'Något gick fel!',
        text: 'Error: ' + error,
      });
    }

    reset();
  };


  return (
    <>
      <AdminSettingText style={{marginTop: '4rem', fontSize: '1.3rem', fontWeight: '500'}}>Lägg till e-postinkorg</AdminSettingText>
      <AdminSettingForm onSubmit={handleSubmit(onSubmit)}>
        <AdminSettingContainer>
          <AdminSettingText>E&#8209;postadress</AdminSettingText>
          <AdminSettingInput
            {...register("email")}
            type="email"
            placeholder="exempel@domän.se"
            id="email"
          />
          {errors.email && (
            <AdminInputError>{`${errors.email.message}`}</AdminInputError>
          )}
        </AdminSettingContainer>

        <AdminSettingContainer>
          <AdminSettingText>Namnge inkorg</AdminSettingText>
          <AdminSettingInput
            {...register("inboxName")}
            type="text"
            placeholder="Namn"
            id="inboxName"
          />
          {errors.inboxName && (
            <AdminInputError>{`${errors.inboxName.message}`}</AdminInputError>
          )}
        </AdminSettingContainer>

        <AdminSettingContainer>
          <AdminSettingText>Hämta mejl sedan vilket datum?</AdminSettingText>
          <AdminSettingInput
            {...register("sync_from_date")}
            type="date"
            id="sync_from_date"
          />
          {errors.sync_from_date && (
            <AdminInputError>{`${errors.sync_from_date.message}`}</AdminInputError>
          )}
        </AdminSettingContainer>

        <AdminButton
          disabled={isSubmitting}
          type="submit"
        >
          Lägg till
        </AdminButton>
      </AdminSettingForm>
    </>
  );
}