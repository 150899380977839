import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ax } from "../Utils";


export const useContentMarketing = (module: string, obj_id: string | undefined, module_level: string | null) => {
  //const queryClient = useQueryClient();
  //console.log("content", !!obj_id);
  //const {data: content } = useQuery<string, Error>({
  return useQuery<string, Error>({
    queryKey: ['content', {module}, { obj_id }],
    queryFn: async (): Promise<string> => {
      if (module_level === 'content') {
        return ax.get(`/marketing_content?obj_id=${obj_id}`).then(res => res.data.content_body);
      }
      else if (module_level === 'variant') {
        return ax.get(`/marketing_campaign_variant?obj_id=${obj_id}`).then(res => res.data.base_content);
      }
      else {
        return Promise.resolve('');
      }
    },
    // queryFn: (): Promise<string> => ax.get(`/marketing?obj_id=${obj_id}`).then(res => res.data.body),
    enabled: !!obj_id,
    initialData: '',
    staleTime: 0,
    refetchOnReconnect: true,
  });

  // const updateContent = (content: string) => {
  //   queryClient.setQueryData<string>(['content', { obj_id }], content);
  // };

  //return { content, updateContent };
};