import React, { useEffect, useState } from 'react';
import { AdminButton, AdminCategoryTitle } from '../../../AdminPage-styling';
import { ax } from '../../../../Utils';
import Table from '../../../AdminComponents/Table';
import { toast } from 'react-toastify';
import { ColumnDef, CellContext } from '@tanstack/react-table';

interface WebScrapeConfig {
  id: string;
  name: string;
  start_url: string;
  status: string;
  last_refreshed_at: string;
  scrape_activated: boolean;
  parse_activated: boolean;
  refresh_interval: number;
}

const WebScrapeConfigTable = () => {
  const [configs, setConfigs] = useState<WebScrapeConfig[]>([]);

  useEffect(() => {
    fetchConfigs();
  }, []);

  const fetchConfigs = async () => {
    try {
      const response = await ax.get('/web_scrape_config/all/new');
      setConfigs(response.data);
    } catch (error) {
      toast.error('Failed to fetch web scrape configs.');
    }
  };

  const handleEdit = (config: WebScrapeConfig) => {
    // Implement the logic to edit the web scrape configuration
    console.log("Editing config:", config);
    // You can open a modal or navigate to an edit page here
  };

  const handleDelete = async (config: WebScrapeConfig) => {
    try {
      await ax.delete(`/web_scrape_config/${config.id}`);
      toast.success('Web scrape config deleted successfully!');
      fetchConfigs(); // Refresh the list after deletion
    } catch (error) {
      toast.error('Failed to delete web scrape config.');
    }
  };

  const columns: ColumnDef<WebScrapeConfig>[] = [
    {
      header: 'Name',
      accessorFn: (row) => row.name,
    },
    {
      header: 'Status',
      accessorFn: (row) => row.status,
    },
    {
      header: 'Start URL',
      accessorFn: (row) => row.start_url,
    },
    {
      header: 'Last Updated',
      accessorFn: (row) => {
        const date = new Date(row.last_refreshed_at);
        return date.toLocaleString('sv-SE', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        });
      },
    },
    {
      header: 'Scrape',
      accessorFn: (row) => row.scrape_activated,
    },
    {
      header: 'Parse',
      accessorFn: (row) => row.parse_activated,
    },
    {
      header: 'Next Update',
      cell: ({ row }) => {
        if (!row.original.last_refreshed_at) {
          return row.original.scrape_activated ? "ASAP" : "";
        }
        const lastUpdated = new Date(row.original.last_refreshed_at);
        const nextUpdate = new Date(lastUpdated.getTime() + row.original.refresh_interval * 60 * 60 * 1000);
        return nextUpdate.toLocaleString('sv-SE', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        });
      },
    },
    {
      header: 'Actions',
      cell: ({ row }) => (
        <>
          <AdminButton onClick={() => handleEdit(row.original)}>Redigera</AdminButton>
          <AdminButton onClick={() => handleDelete(row.original)}>Radera</AdminButton>
        </>
      ),
    },
  ];

  return (
    <div>
      <AdminCategoryTitle>Webbplatser</AdminCategoryTitle>
      <Table columns={columns} data={configs} />
    </div>
  );
};

export default WebScrapeConfigTable; 