

import { useQueryClient } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { ax } from "../Utils";
import { MarketingContent } from "../Types";

export const useMarketingContentMutation = () => {
  const queryClient = useQueryClient();

  const marketingContentMutation = useMutation({
    mutationFn: (requestBody: any) => {
      const result = requestBody.id ?
        ax.patch('/marketing_content', requestBody)
        : ax.post('/marketing_content', requestBody)
      return result;
    },
    onMutate: async (variables) => {
      if (variables.id) {
        // Cancel outgoing refetches
        await queryClient.cancelQueries({
          queryKey: ['marketing', 'content', 'all', 'list']
        });

        console.log('variables', variables);
        // Optimistically update the cache
        queryClient.setQueryData(['marketing', 'content', 'all', 'list', {variant_id: variables.variant_id}], (old: MarketingContent[] = []) => {
          console.log('old', old);
          console.log('new', old.map(content => content.id === variables.id ? { ...content, ...variables } : content));
          return old.map(content => 
            content.id === variables.id 
              ? { ...content, ...variables }
              : content
          );
        });
        
        queryClient.setQueryData(['marketing_content', { obj_id: variables.id }], (old: MarketingContent) => {
          return { ...old, ...variables };
        });
      }
    },
    onError: (err, variables) => {
      queryClient.invalidateQueries({ queryKey: ['marketing', 'content', 'all', 'list', {variant_id: variables.variant_id}] });
    },
    onSuccess: (response, variables) => {
      if (!variables.id) {
        queryClient.setQueryData(['marketing', 'content', 'all', 'list', {variant_id: variables.variant_id}], (old: MarketingContent[] = []) => {
          return [...old, response.data];
        });
      }
      if (variables.content_title) {
        queryClient.invalidateQueries({ queryKey: ['marketing_campaign', 'all', 'list'] });
      }
      if (variables.content_body) {
        queryClient.setQueryData(['content', { module: 'marketing' }, { obj_id: variables.id }], response.data.content_body);
      } 
    },
    // onSuccess: (response, variables) => {
    //   console.log('response', response.data);
    //   queryClient.setQueryData(['marketing', 'content', 'all', 'list', {variant_id: response.data.variant_id}], (oldData: MarketingContent[]) => {
    //     return oldData.map(content => content.id === response.data.id ? response.data : content);
    //   });
    // },
  });

  return marketingContentMutation;
};