import React, { useState, useEffect, useMemo, useRef, useCallback, memo } from 'react';
import { Box, Typography, Button, TextField, Select, MenuItem, Drawer, Breadcrumbs, Link as MuiLink, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ax } from '../../Utils';
import { useNavigate, useParams } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import Table from '../../AdminPage/AdminComponents/Table';
import { RichTextEditor, Link } from '@mantine/tiptap';
import { Editor, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import Image from '@tiptap/extension-image';
import Highlight from '@tiptap/extension-highlight';
import { MantineProvider } from '@mantine/core';
import { useAssistantChat } from '../../Queries/useAssistantChat';
import { useAnswerLoading } from '../../Queries/useAnswerLoading';
import { Campaign, Variant, MarketingContent } from '../../Types';
import { useCampaignVariantMutation } from '../../Mutations/CampaignVariantMutation';
import { debounce } from 'lodash';
import { useMarketingContentList } from '../../Queries/useMarketingContentList';
import { queryClient } from '../../lib/queryClient';
import { useMarketingContentMutation } from '../../Mutations/MarketingContentMutation';
import { useMarketingChannels } from '../../Queries/useMarketingChannels';
import '../../AdminPage/AdminComponents/Tables.css';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { AdminSettingContainer, AdminSettingText, AdminSettingTextarea } from '../../AdminPage/AdminPage-styling';
import { AdminSettingInput } from '../../AdminPage/AdminPage-styling';
import { MarketingSettingText } from '../MarketingPageNew-styling';
import { FaAlignCenter, FaAlignJustify, FaAlignLeft, FaAlignRight, FaBold, FaEdit, FaHeading, FaHighlighter, FaItalic, FaListOl, FaListUl, FaRedo, FaStrikethrough, FaSubscript, FaSuperscript, FaTrash, FaUnderline, FaUndo } from 'react-icons/fa';
import { useCampaignVariant } from '../../Queries/useCampaignVariant';
import { ToolsGroup } from '../../MarketingPage/MarketingPage-styling';
import { WordCountText } from '../../MarketingPage/MarketingPage-styling';
import { CharacterCount } from '@tiptap/extension-character-count';


// interface VariantDetailsProps {
//   variant: Variant | null;
// }

const EditableCell = memo(({ getValue, row, mutate, field }: {
  getValue: () => string;
  row: any;
  mutate: (data: any) => void;
  field: keyof MarketingContent;
}) => {
  const [localText, setLocalText] = useState(getValue() ?? '');

  useEffect(() => {
    setLocalText(getValue() ?? '');
  }, [getValue]);

  const handleBlur = useCallback((e: { target: { value: string } }) => {
    if (e.target.value !== row.original[field] && (e.target.value || row.original[field])) {
      mutate({ 
        id: row.original.id, 
        [field]: e.target.value,
        variant_id: row.original.variant_id
      });
    }
  }, [row.original, field, mutate]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedValue = e.target.value;
    setLocalText(updatedValue);
  }, []);

  return (
    <input
      className="table-input"
      value={localText}
      onClick={(e) => e.stopPropagation()}
      onChange={handleChange}
      onBlur={handleBlur}
      style={{ pointerEvents: 'none' }}
    />
  );
});

EditableCell.displayName = 'EditableCell';

type VariantDetailsProps = {
  editorContent: string;
  editorRef: React.MutableRefObject<Editor | null>;
}

const VariantDetails = ({ editorContent, editorRef }: VariantDetailsProps) => {
  const { campaignId, variantId } = useParams<{ campaignId: string; variantId: string }>();
  //const [marketingContents, setMarketingContents] = useState<MarketingContent[]>([]);
  //const [channels, setChannels] = useState<{ id: string; name: string }[]>([]);
  const navigate = useNavigate();
  const [variantData, setVariantData] = useState<Variant | null>(null);
  const [isContentModified, setIsContentModified] = useState(false);
  const [lastSaved, setLastSaved] = useState<string | null>(null);
  
  const { data: marketingContents } = useMarketingContentList(variantId);
  const { data: channels } = useMarketingChannels();
  const { data: variant } = useCampaignVariant(variantId);

  const campaignVariantMutation = useCampaignVariantMutation();
  const marketingContentMutation = useMarketingContentMutation();

  
  
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      CharacterCount,
    ],
    content: editorContent,
    onUpdate: () => {
      // setIsContentModified(true);
      // debouncedVariantUpdate({ id: variantId, base_content: editor?.getText() });
    },
  });
  
  useEffect(() => {
    if (variant) {
      setVariantData(variant);
      // editor?.commands.setContent(variant.base_content ? variant.base_content.replace(/\n/g, '<br>') : '');
    }
  }, [variant]);

  useEffect(() => {
    if (editorRef.current) {
      if (editorContent) {
        editorRef.current.commands.setContent(editorContent.replace(/\n/g, '<br>'));
      }
    }
  }, [editorContent]);

  const handleResizeRef = useRef<(() => void) | null>(null);

  useEffect(() => {
    if (!editor) return; // Early return if no editor
  
    editorRef.current = editor;

    // Create an observer instance
    const observer = new MutationObserver((mutations) => {
      // Only proceed if we haven't already set up the resize handler
      if (handleResizeRef.current) return;
  
      const proseMirrorElement = document.querySelector('.ProseMirror') as HTMLElement;
      if (proseMirrorElement) {
        const greatGrandparentElement = proseMirrorElement?.parentElement?.parentElement?.parentElement;
        
        if (greatGrandparentElement) {
          proseMirrorElement.style.outline = 'none';
          
          const handleResize = () => {
            // Cache the height calculation
            const greatGrandparentHeight = greatGrandparentElement.clientHeight;
            // Use transform instead of minHeight for better performance
            proseMirrorElement.style.minHeight = `${greatGrandparentHeight - 75}px`;
          };
  
          handleResizeRef.current = handleResize;
          handleResize(); // Initial resize
          
          // Use passive event listener for better scroll performance
          window.addEventListener('resize', handleResize, { passive: true });
          
          // Once we've found and set up the element, disconnect the observer
          observer.disconnect();
        }
      }
    });
  
    // Start observing the document with the configured parameters
    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  
    return () => {
      observer.disconnect();
      if (handleResizeRef.current) {
        window.removeEventListener('resize', handleResizeRef.current);
      }
    };
  }, [editor]);

  const handleEditContent = (contentId: string) => {
    navigate(`/marketing/${campaignId}/${variantId}/${contentId}`);
  };

  const handleCreateContent = () => {
    const newContent = {
      variant_id: variantId,
      campaign_id: campaignId,
    };
    try {
      marketingContentMutation.mutate(newContent, {
        // onSuccess: (response) => {
        //   navigate(`/marketing/${campaignId}/${variantId}/${response.data.id}`);
        // }
      });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Request failed!',
        text: 'Contact support if the problem persists.',
      });
    }
  };

  const handleClickDeleteContent = (contentId: string) => {
    Swal.fire({
      title: 'Do you want to delete this content?',
      text: 'You will not be able to recover content that has been deleted!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      confirmButtonColor: '#d33',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteContent(contentId);
      }
    });
  };

  const handleDeleteContent = (contentId: string) => {
    ax.delete(`/marketing_content/${contentId}`).then(() => {
      queryClient.setQueryData(['marketing', 'content', 'all', 'list', {variant_id: variantId}], (oldData: MarketingContent[]) => {
        return oldData.filter(content => content.id !== contentId);
      });
      queryClient.setQueryData(['marketing_campaign', 'all', 'list'], (oldData: Campaign[]) => {
        return oldData.map(campaign => {
          if (campaign.id === campaignId) {
            return {
              ...campaign,
              variants: campaign.variants.map(variant => variant.id === variantId 
                ? { ...variant, contents: variant.contents.filter(content => content.id !== contentId) } : variant)
            };
          }
          return campaign;
        });
      });
    });
  };

  const columns = useMemo<ColumnDef<MarketingContent, any>[]>(
    () => [
      {
        accessorKey: 'content_title',
        header: 'Title',
        cell: (info) => (
          <EditableCell
            getValue={info.getValue}
            row={info.row}
            mutate={marketingContentMutation.mutate}
            field="content_title"
          />
        ),
      },
      {
        accessorKey: 'target_group_description',
        header: 'Target Group',
        cell: (info) => (
          <EditableCell
            getValue={info.getValue}
            row={info.row}
            mutate={marketingContentMutation.mutate}
            field="target_group_description"
          />
        ),
      },
      {
        accessorKey: 'channel_id',
        header: 'Channel',
        cell: (info) => (
          <Select
            value={channels?.some(channel => channel.id === info.getValue()) ? info.getValue() : ''}
            onChange={(e) => {
              const updatedValue = e.target.value;
              marketingContentMutation.mutate({ id: info.row.original.id, channel_id: updatedValue, variant_id: variantId });
            }}
            sx={{
              color: '#fff',
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #000'
              }
            }}
            fullWidth
          >
            {channels?.map(channel => (
              <MenuItem key={channel.id} value={channel.id}>
                {channel.name}
              </MenuItem>
            ))}
          </Select>
        ),
      },
      {
        accessorKey: 'language',
        header: 'Language',
        cell: (info) => (
          <EditableCell
            getValue={info.getValue}
            row={info.row}
            mutate={marketingContentMutation.mutate}
            field="language"
          />
        ),
      },
      {
        accessorKey: 'is_ready',
        header: 'Is Ready',
        cell: (info) => (
          <Select
            value={info.getValue() ? 'yes' : 'no'}
            onChange={(e) => {
              const updatedValue = e.target.value === 'yes';
              marketingContentMutation.mutate({ id: info.row.original.id, is_ready: updatedValue, variant_id: variantId });
            }}
            sx={{
              color: '#fff',
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #000'
              }
            }}
            fullWidth
          >
            <MenuItem value="yes">Yes</MenuItem>
            <MenuItem value="no">No</MenuItem>
          </Select>
        ),
      },
      {
        accessorKey: 'last_updated_at',
        header: 'Last Updated',
        cell: (info) => new Date(info.getValue()).toLocaleString(),
      },
      {
        accessorKey: 'edit',
        header: 'Edit',
        cell: (info) => (
          <Button
            onClick={() => handleEditContent(info.row.original.id)}
            sx={{
              color: '#f0f0f0',
              '&:hover': {
                color: '#ccc',
              }
            }}
          >
            <FaEdit size={20} />
          </Button>
        ),
      },
      {
        accessorKey: 'delete',
        header: 'Delete',
        cell: (info) => (
          <Button 
            onClick={() => handleClickDeleteContent(info.row.original.id)}
            sx={{
              color: '#f0f0f0',
              '&:hover': {
                color: '#d33',
              }
            }}
          >
            <FaTrash size={20} />
          </Button>
        ),
      },
    ],
    [marketingContents]
  );

  const handleGenerateContent = (generatedText: string) => {
    if (editor) {
      editor.commands.setContent(generatedText);
      ax.patch('/marketing_campaign_variant/', { id: variantId, base_content: generatedText });
      alert('Content generated and saved successfully!');
    }
  };

  const debouncedVariantUpdate = useCallback(
    debounce((requestBody: any) => {
      campaignVariantMutation.mutate(requestBody);
  }, 2000), []);
  
  const debouncedMarketingContentUpdate = useCallback(
    debounce((requestBody: any) => {
      marketingContentMutation.mutate(requestBody);
    }, 2000),
    []
  );

  useEffect(() => {
    return () => {
      debouncedVariantUpdate.flush();
    };
  }, [debouncedVariantUpdate]);

  if (!variantData) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <Typography variant="h5" component="h2" gutterBottom>
        Variant Details
      </Typography>
      <Box sx={{ mb: 2 }}>
        <AdminSettingContainer>
          <MarketingSettingText>Variant Name</MarketingSettingText>
          <AdminSettingInput
            value={variantData?.name || ''}
            onChange={(e: { target: { value: string; }; }) => {
              const updatedValue = e.target.value;
              setVariantData(prevData => prevData ? { ...prevData, name: updatedValue } : null);
              // debouncedVariantUpdate({ id: variantId, name: updatedValue });
            }}
            placeholder="Enter variant name"
            onBlur={(e: { target: { value: string; }; }) => {
              const updatedValue = e.target.value;
              if (updatedValue !== variant?.name) {
                campaignVariantMutation.mutate({ id: variantId, name: updatedValue });
              }
            }}
          />
        </AdminSettingContainer>
        <AdminSettingContainer>
          <MarketingSettingText>Variant Description</MarketingSettingText>
          <AdminSettingTextarea
            value={variantData?.description || ''}
            onChange={(e: { target: { value: string; }; }) => {
              const updatedValue = e.target.value;
              setVariantData(prevData => prevData ? { ...prevData, description: updatedValue } : null);
              // debouncedVariantUpdate({ id: variantId, description: updatedValue });
            }}
            placeholder="Enter variant description"
            onBlur={(e: { target: { value: string; }; }) => {
              const updatedValue = e.target.value;
              if (updatedValue !== variant?.description) {
                campaignVariantMutation.mutate({ id: variantId, description: updatedValue });
              }
            }}
          />
        </AdminSettingContainer>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="h6" component="h3" gutterBottom>
          Variant Contents
        </Typography>
        <Table columns={columns} data={marketingContents || []} />
        <Button variant="contained" color="primary" onClick={handleCreateContent} sx={{ mt: 2 }}>
          Add new target
        </Button>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography variant="h6" component="h3" gutterBottom>
          Base Content
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          This is the base text that will be used to generate targeted marketing content.
        </Typography>
        <MantineProvider>
        <RichTextEditor 
          editor={editor}
          style={{
            height: '100%',
            marginBottom: '5px',
            minHeight: '400px',
            minWidth: '210px',
            width: 'calc(100% - 10px)',
            border: 'solid',
            borderColor: '#444', 
            borderWidth: '5px', 
            borderRadius: '6px', 
            alignSelf: 'safe start',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            position: 'relative',
          }}
        >
          <RichTextEditor.Toolbar style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#444', border: 'solid', borderColor: '#444', borderWidth: '3px 0px 3px 0px'}}>
            <ToolsGroup>
              <button
                onClick={() => editor?.chain().focus().toggleBold().run()}
                className={editor?.isActive('bold') ? 'is-active' : ''}
              >
                <FaBold />
              </button>
              <button
                onClick={() => editor?.chain().focus().toggleItalic().run()}
                className={editor?.isActive('italic') ? 'is-active' : ''}
              >
                <FaItalic />
              </button>
              <button
                onClick={() => editor?.chain().focus().toggleUnderline().run()}
                className={editor?.isActive('underline') ? 'is-active' : ''}
              >
                <FaUnderline />
              </button>
              <button
                onClick={() => editor?.chain().focus().toggleStrike().run()}
                className={editor?.isActive('strike') ? 'is-active' : ''}
              >
                <FaStrikethrough />
              </button>
              <button
                onClick={() => editor?.chain().focus().toggleHighlight().run()}
                className={editor?.isActive('highlight') ? 'is-active' : ''}
              >
                <FaHighlighter />
              </button>
              <button
                onClick={() => editor?.chain().focus().toggleHeading({ level: 1 }).run()}
                className={editor?.isActive('heading', { level: 1 }) ? 'is-active' : ''}
              >
                <FaHeading />
              </button>
              <button
                onClick={() => editor?.chain().focus().toggleBulletList().run()}
                className={editor?.isActive('bulletList') ? 'is-active' : ''}
              >
                <FaListUl />
              </button>
              <button
                onClick={() => editor?.chain().focus().toggleOrderedList().run()}
                className={editor?.isActive('orderedList') ? 'is-active' : ''}
              >
                <FaListOl />
              </button>
            </ToolsGroup>
{/* 
            <ToolsGroup>
              <button
                onClick={() => editor?.chain().focus().setTextAlign('left').run()}
                className={editor?.isActive('textAlign', 'left') ? 'is-active' : ''}
              >
                <FaAlignLeft />
              </button>
              <button
                onClick={() => editor?.chain().focus().setTextAlign('center').run()}
                className={editor?.isActive('textAlign', 'center') ? 'is-active' : ''}
              >
                <FaAlignCenter />
              </button>
              <button
                onClick={() => editor?.chain().focus().setTextAlign('justify').run()}
                className={editor?.isActive('textAlign', 'justify') ? 'is-active' : ''}
              >
                <FaAlignJustify />
              </button>
              <button
                onClick={() => editor?.chain().focus().setTextAlign('right').run()}
                className={editor?.isActive('textAlign', { alignment: 'right'}) ? 'is-active' : ''}
              >
                <FaAlignRight />
              </button>
            </ToolsGroup> */}

            <ToolsGroup>
              <button 
                onClick={() => editor?.chain().focus().undo().run()}
                disabled={!editor?.can().undo()}
                className={editor?.can().undo() ? '' : 'is-disabled'}
              >
                <FaUndo/>
              </button>
              <button 
                onClick={() => editor?.chain().focus().redo().run()} 
                disabled={!editor?.can().redo()}
                className={editor?.can().redo() ? '' : 'is-disabled'}
              >
                <FaRedo/>
              </button>
            </ToolsGroup>
          </RichTextEditor.Toolbar>
        <RichTextEditor.Content
          style={{ maxHeight: 'calc(100% - 55px)', overflowY: 'auto',  color: '#fff', padding: '0 10px 0px 10px', border: 'none'}}
        />
        <WordCountText>{editor?.storage.characterCount.words()} ord</WordCountText>
        </RichTextEditor>
      </MantineProvider>
      <Stack direction="row" spacing={1}>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={() => {
            if (editor) {
              const updatedContent = editor.getHTML();
              if (updatedContent !== variantData?.base_content) {
                try {
                  campaignVariantMutation.mutate({ id: variantId, base_content: updatedContent }, {
                    onSuccess: () => {
                      setLastSaved(new Date().toLocaleString());
                      toast.success('Content saved successfully!', { autoClose: 2000 });
                    }
                  });
                } catch (error) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Failed to save base content.',
                    text: 'Please try again.',
                  });
                }
              }
            } else {
              toast.error('Editor is not initialized.');
            }
          }}
          disabled={editor?.getHTML() === variantData?.base_content || campaignVariantMutation.isPending}
          sx={{ mt: 2 }}
        >
          Save
        </Button>
        {lastSaved && <div style={{ lineHeight: '2.25' }}>Last saved: {lastSaved}</div>}
      </Stack>
      </Box>
    </Box>
  );
};

export default VariantDetails;
