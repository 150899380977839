import { TWebsiteSchema, websiteSchema } from "../../../../Types";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ax } from "../../../../Utils";
import { AdminButton, AdminInputError, AdminSettingContainer, AdminSettingForm, AdminSettingInput, AdminSettingSelectBox, AdminSettingText, ShowAdvancedOptionsButton, SmallTextSpan, AdminPopupWindow, AdminCategoryTitle } from "../../../../AdminPage/AdminPage-styling";
import { PopupOverlay } from "../../../../ErrandsPage/ErrandsPage-styling";
import { useDataModels } from "../../../../Queries/useDataModels";
import Swal from "sweetalert2";


export default function AddWebsite() {

  const { data: dataModels } = useDataModels();
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [buttonText, setButtonText] = useState("Visa avancerade alternativ");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
    watch,
    setValue,
  } = useForm<TWebsiteSchema>({
    resolver: zodResolver(websiteSchema),
  });

  // Watch the state of 'follow_links' checkbox
  const followLinks = watch("follow_links", true);

  // Automatically uncheck 'only_follow_start_url_children' when 'follow_links' is unchecked
  useEffect(() => {
    if (showAdvancedOptions) {
      if (!followLinks) {
        setValue("only_follow_start_url_children", false); // Uncheck the checkbox when 'follow_links' is false
      }
      if (followLinks) {
        setValue("only_follow_start_url_children", true); // Check the checkbox when 'follow_links' is true
      }
    }
  }, [followLinks, setValue]);

  const toggleAdvancedOptions = () => {
    if (showAdvancedOptions) {
      setButtonText("Visa avancerade alternativ");
    }
    else {
      setButtonText("Dölj avancerade alternativ");
    }
    setShowAdvancedOptions(!showAdvancedOptions);
  };

  const onSubmit = async (data: TWebsiteSchema) => {
    console.log("submitting form", data);
    const requestBody = {
      name: data.name,
      start_url: data.website,
      //follow_links: data.follow_links,
      //only_follow_start_url_children: data.only_follow_start_url_children,
      refresh_interval: data.refresh_interval,
      //data_model_id: data.data_model_id,
    };

    try {
      await ax.post('/web_scrape_config/new', requestBody)
         .then(() => {
          toast.success("Webbplatsen har lagts till!");
          reset();
         })
    }
    catch (error) {
      Swal.fire({
        title: "Förfrågan misslyckades!",
        text: "Kunde inte lägga till webbplats. Försök igen senare.",
        icon: "error"
      });
      return;
    }
    
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <button onClick={openModal}>Add New Website</button>
      {isModalOpen && (
        <PopupOverlay>
          <AdminPopupWindow style={{ minHeight: '500px', minWidth: '400px' }}>
            <button onClick={closeModal} style={{ position: 'absolute', top: '10px', right: '10px', background: 'none', border: 'none', fontSize: '20px', cursor: 'pointer' }}>×</button>
            <AdminSettingForm onSubmit={handleSubmit(onSubmit)}>
              <AdminCategoryTitle style={{ marginBottom: '20px', color: '#000' }}>
                Lägg till ny webbplats
              </AdminCategoryTitle>
              <AdminSettingContainer>
                <AdminSettingText style={{ color: '#000' }}>URL</AdminSettingText>
                <AdminSettingInput
                  {...register("website")}
                  type="website"
                  placeholder="yourwebsite.com"
                  id="website"
                />
                {errors.website && (
                  <AdminInputError>{`${errors.website.message}`}</AdminInputError>
                )}
              </AdminSettingContainer>

              <AdminSettingContainer>
                <AdminSettingText style={{ color: '#000' }}>Namn</AdminSettingText>
                <AdminSettingInput
                  {...register("name")}
                  placeholder="User friendly name of the website"
                  id="name"
                />
                {errors.name && (
                  <AdminInputError>{`${errors.name.message}`}</AdminInputError>
                )}
              </AdminSettingContainer>
              <ShowAdvancedOptionsButton
                type="button"
                onClick={toggleAdvancedOptions}
                style={{ color: '#000' }}
              >{buttonText}
              </ShowAdvancedOptionsButton>
              {showAdvancedOptions && (
                <>
                  {/*<AdminSettingContainer>
                    <AdminSettingText style={{ color: '#000' }}>Följ länkar?</AdminSettingText>
                    <input
                      {...register("follow_links")}
                      type="checkbox"
                      id="follow_links"
                      defaultChecked={true}
                    />
                  </AdminSettingContainer>*/}

                  {/*<AdminSettingContainer>
                    <AdminSettingText style={{ color: '#000' }}>Följ bara länkar som innehåller start-URLen?</AdminSettingText>
                    <input
                      {...register("only_follow_start_url_children")}
                      type="checkbox"
                      id="only_follow_start_url_children"
                      disabled={!followLinks}
                      defaultChecked={true}
                    />
                  </AdminSettingContainer>*/}


                  <AdminSettingContainer>
                    <AdminSettingText style={{ color: '#000' }}>Intervall för uppdatering i timmar</AdminSettingText>
                    <SmallTextSpan>(Hur ofta webbplatsen ska läsas om)</SmallTextSpan>
                    <AdminSettingInput
                      {...register("refresh_interval", { valueAsNumber: true })}
                      type="number"
                      defaultValue={168}
                      id="refresh_interval"
                    />
                    {errors.refresh_interval && (
                      <AdminInputError>{`${errors.refresh_interval.message}`}</AdminInputError>
                    )}
                  </AdminSettingContainer>

                  {/*<AdminSettingContainer>
                    <AdminSettingText style={{ color: '#000' }}>Datatyp</AdminSettingText>
                    <SmallTextSpan>(I vilken datastruktur ska informationen sparas)</SmallTextSpan>
                    <AdminSettingSelectBox
                      {...register("data_model_id")}
                      id="data_model_id"
                    >
                      {dataModels?.map((model, index) => (
                        <option key={index} value={model.id}>{model.name}</option>
                      ))}
                    </AdminSettingSelectBox>
                  </AdminSettingContainer>*/}
                </>
              )}
              <AdminButton
                disabled={isSubmitting}
                type="submit"
              >
                Lägg till
              </AdminButton>
            </AdminSettingForm>
          </AdminPopupWindow>
        </PopupOverlay>
      )}
    </>
  );
}
