import { Template } from "../Types";
import { ax } from "../Utils";
import { useQuery } from "@tanstack/react-query"


export const useTemplates = () => {
  return useQuery<Template[], Error>({
    queryKey: ['template', 'all'],
    queryFn: (): Promise<Template[]> => ax.get('/template/all/').then(res => res.data),
    refetchOnReconnect: true,
  });
};