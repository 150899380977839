import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, TextField, Select, MenuItem, Button, Breadcrumbs, Link as MuiLink, Stack } from '@mui/material';
import { ax } from '../../Utils';
import { useParams, useNavigate } from 'react-router-dom';
import { Link, RichTextEditor } from '@mantine/tiptap';
import { Editor, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import Image from '@tiptap/extension-image';
import Highlight from '@tiptap/extension-highlight';
import { MantineProvider } from '@mantine/core';
import { MarketingContent } from '../../Types';
import { useMarketingChannels } from '../../Queries/useMarketingChannels';
import { MarketingSettingText } from '../MarketingPageNew-styling';
import { AdminSettingContainer, AdminSettingInput, AdminSettingSelectBox } from '../../AdminPage/AdminPage-styling';
import { useMarketingContentMutation } from '../../Mutations/MarketingContentMutation';
import { useMarketingContent } from '../../Queries/useMarketingContent';
import CharacterCount from '@tiptap/extension-character-count';
import { FaUndo, FaRedo } from 'react-icons/fa';
import { FaBold, FaItalic, FaUnderline, FaStrikethrough, FaHighlighter, FaHeading, FaSubscript, FaSuperscript, FaListUl, FaListOl, FaAlignLeft, FaAlignCenter, FaAlignJustify, FaAlignRight } from 'react-icons/fa6';
import { TextEditorBottomSection, ToolsGroup, WordCountText } from '../../MarketingPage/MarketingPage-styling';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';


type ContentDetailsProps = {
  // content: MarketingContent | null;
  editorContent: string;
  editorRef: React.MutableRefObject<Editor | null>;
}

const ContentDetails = ({ editorContent, editorRef }: ContentDetailsProps) => {
  const { campaignId, variantId, contentId } = useParams<{ campaignId: string; variantId: string; contentId: string }>();
  const [contentData, setContentData] = useState<MarketingContent | null>(null);
  const [lastSaved, setLastSaved] = useState<string | null>(null);

  // const [marketingContents, setMarketingContents] = useState<MarketingContent[]>([]);
  // const [channels, setChannels] = useState<{ id: string; name: string }[]>([]);
  // const [inputText, setInputText] = useState('');

  const { data: channels } = useMarketingChannels();
  const { data: content } = useMarketingContent(contentId);

  const marketingContentMutation = useMarketingContentMutation();

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      CharacterCount,
    ],
    content: editorContent,
    onUpdate: ({ editor }) => {
      // const updatedContent = editor.getHTML();
      // marketingContentMutation.mutate({ id: contentId, content_body: updatedContent, variant_id: variantId });
    },
  });

  useEffect(() => {
    if (content) {
      setContentData(content);
      // editor?.commands.setContent(content.content_body ? content.content_body.replace(/\n/g, '<br>') : '');
    }
  }, [content]);

  useEffect(() => {
    console.log('editorRef', editorRef.current);
    if (editorRef.current) {
      editorRef.current.commands.setContent(editorContent?.replace(/\n/g, '<br>') || '');
    }
  }, [editorContent]);

  const handleResizeRef = useRef<(() => void) | null>(null);

  useEffect(() => {
    if (!editor) return; // Early return if no editor

    editorRef.current = editor;
  
    // Create an observer instance
    const observer = new MutationObserver((mutations) => {
      // Only proceed if we haven't already set up the resize handler
      if (handleResizeRef.current) return;
  
      const proseMirrorElement = document.querySelector('.ProseMirror') as HTMLElement;
      if (proseMirrorElement) {
        const greatGrandparentElement = proseMirrorElement?.parentElement?.parentElement?.parentElement;
        
        if (greatGrandparentElement) {
          proseMirrorElement.style.outline = 'none';
          
          const handleResize = () => {
            // Cache the height calculation
            const greatGrandparentHeight = greatGrandparentElement.clientHeight;
            // Use transform instead of minHeight for better performance
            proseMirrorElement.style.minHeight = `${greatGrandparentHeight - 75}px`;
          };
  
          handleResizeRef.current = handleResize;
          handleResize(); // Initial resize
          
          // Use passive event listener for better scroll performance
          window.addEventListener('resize', handleResize, { passive: true });
          
          // Once we've found and set up the element, disconnect the observer
          observer.disconnect();
        }
      }
    });
  
    // Start observing the document with the configured parameters
    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  
    return () => {
      observer.disconnect();
      if (handleResizeRef.current) {
        window.removeEventListener('resize', handleResizeRef.current);
      }
    };
  }, [editor]); // Only run when editor changes

  if (!contentData) {
    return <div>Loading...</div>;
  }

  return (
    <Box height="100%" flex={1} display="flex" flexDirection="column">
      <Typography variant="h5" component="h2" gutterBottom>
        Content Editor
      </Typography>
      <Box sx={{ mb: 2 }}>
        <AdminSettingContainer>
          <MarketingSettingText>Title</MarketingSettingText>
          <AdminSettingInput
            style={{ fontSize: '1.3rem', padding: "12px 8px"}}
            value={contentData?.content_title || ''}
            onChange={(e: { target: { value: string; }; }) => setContentData((prevData) => prevData ? { ...prevData, content_title: e.target.value } : null)}
            placeholder="Enter title"
            onBlur={(e: { target: { value: string; }; }) => {
              const updatedValue = e.target.value;
              if (updatedValue !== content?.content_title) {
                marketingContentMutation.mutate({ id: contentId, content_title: updatedValue, variant_id: variantId });
              }
            }}
          />
        </AdminSettingContainer>
        <AdminSettingContainer>
          <MarketingSettingText>Channel</MarketingSettingText>
          <AdminSettingSelectBox
            value={contentData?.channel_id || ''}
            onChange={(e: { target: { value: string; }; }) => {
              const updatedValue = e.target.value;
              setContentData(prevData => prevData ? { ...prevData, channel_id: updatedValue } : null);
              marketingContentMutation.mutate({ id: contentId, channel_id: updatedValue, variant_id: variantId });
            }}
          >
            {channels?.map(channel => (
              <option key={channel.id} value={channel.id}>
                {channel.name}
              </option>
            ))}
          </AdminSettingSelectBox>
        </AdminSettingContainer>
        <AdminSettingContainer>
          <MarketingSettingText>Target Group Description</MarketingSettingText>
          <AdminSettingInput
            value={contentData?.target_group_description || ''}
            onChange={(e: { target: { value: string; }; }) => setContentData(prevData => prevData ? { ...prevData, target_group_description: e.target.value } : null)}
            placeholder="Describe the target group"
            onBlur={(e: { target: { value: string; }; }) => {
              const updatedValue = e.target.value;
              if (updatedValue !== content?.target_group_description) {
                marketingContentMutation.mutate({ id: contentId, target_group_description: updatedValue, variant_id: variantId });
              }
            }}
          />
        </AdminSettingContainer>
        <AdminSettingContainer>
          <MarketingSettingText>Language</MarketingSettingText>
          <AdminSettingInput
            value={contentData?.language || ''}
            onChange={(e: { target: { value: string; }; }) => setContentData(prevData => prevData ? { ...prevData, language: e.target.value } : null)}
            placeholder="Enter language"
            onBlur={(e: { target: { value: string; }; }) => {
              const updatedValue = e.target.value;
              if (updatedValue !== content?.language) {
                marketingContentMutation.mutate({ id: contentId, language: updatedValue, variant_id: variantId });
              }
            }}
          />
        </AdminSettingContainer>
        
      </Box>
      <Typography variant="h6" component="h3" gutterBottom>
        Content Body
      </Typography>
      <MantineProvider>
        <RichTextEditor 
          editor={editor}
          style={{
            height: '100%',
            marginBottom: '5px',
            minHeight: '400px',
            minWidth: '210px',
            width: 'calc(100% - 10px)',
            border: 'solid',
            borderColor: '#444', 
            borderWidth: '5px', 
            borderRadius: '6px', 
            alignSelf: 'safe start',
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
            position: 'relative',
          }}
        >
          <RichTextEditor.Toolbar style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#444', border: 'solid', borderColor: '#444', borderWidth: '3px 0px 3px 0px'}}>
            <ToolsGroup>
              <button
                onClick={() => editor?.chain().focus().toggleBold().run()}
                className={editor?.isActive('bold') ? 'is-active' : ''}
              >
                <FaBold />
              </button>
              <button
                onClick={() => editor?.chain().focus().toggleItalic().run()}
                className={editor?.isActive('italic') ? 'is-active' : ''}
              >
                <FaItalic />
              </button>
              <button
                onClick={() => editor?.chain().focus().toggleUnderline().run()}
                className={editor?.isActive('underline') ? 'is-active' : ''}
              >
                <FaUnderline />
              </button>
              <button
                onClick={() => editor?.chain().focus().toggleStrike().run()}
                className={editor?.isActive('strike') ? 'is-active' : ''}
              >
                <FaStrikethrough />
              </button>
              <button
                onClick={() => editor?.chain().focus().toggleHighlight().run()}
                className={editor?.isActive('highlight') ? 'is-active' : ''}
              >
                <FaHighlighter />
              </button>
              <button
                onClick={() => editor?.chain().focus().toggleHeading({ level: 1 }).run()}
                className={editor?.isActive('heading', { level: 1 }) ? 'is-active' : ''}
              >
                <FaHeading />
              </button>
              <button
                onClick={() => editor?.chain().focus().toggleSubscript().run()}
                className={editor?.isActive('subscript') ? 'is-active' : ''}
              >
                <FaSubscript />
              </button>
              <button
                onClick={() => editor?.chain().focus().toggleSuperscript().run()}
                className={editor?.isActive('superscript') ? 'is-active' : ''}
              >
                <FaSuperscript />
              </button>
            </ToolsGroup>

            <ToolsGroup>
              <button
                onClick={() => editor?.chain().focus().toggleBulletList().run()}
                className={editor?.isActive('bulletList') ? 'is-active' : ''}
              >
                <FaListUl />
              </button>
              <button
                onClick={() => editor?.chain().focus().toggleOrderedList().run()}
                className={editor?.isActive('orderedList') ? 'is-active' : ''}
              >
                <FaListOl />
              </button>
            </ToolsGroup>

            <ToolsGroup>
              <button
                onClick={() => editor?.chain().focus().setTextAlign('left').run()}
                className={editor?.isActive('textAlign', 'left') ? 'is-active' : ''}
              >
                <FaAlignLeft />
              </button>
              <button
                onClick={() => editor?.chain().focus().setTextAlign('center').run()}
                className={editor?.isActive('textAlign', 'center') ? 'is-active' : ''}
              >
                <FaAlignCenter />
              </button>
              <button
                onClick={() => editor?.chain().focus().setTextAlign('justify').run()}
                className={editor?.isActive('textAlign', 'justify') ? 'is-active' : ''}
              >
                <FaAlignJustify />
              </button>
              <button
                onClick={() => editor?.chain().focus().setTextAlign('right').run()}
                className={editor?.isActive('textAlign', { alignment: 'right'}) ? 'is-active' : ''}
              >
                <FaAlignRight />
              </button>
            </ToolsGroup>

            <ToolsGroup>
              <button 
                onClick={() => editor?.chain().focus().undo().run()}
                disabled={!editor?.can().undo()}
                className={editor?.can().undo() ? '' : 'is-disabled'}
              >
                <FaUndo/>
              </button>
              <button 
                onClick={() => editor?.chain().focus().redo().run()} 
                disabled={!editor?.can().redo()}
                className={editor?.can().redo() ? '' : 'is-disabled'}
              >
                <FaRedo/>
              </button>
            </ToolsGroup>
          </RichTextEditor.Toolbar>
        <RichTextEditor.Content
          style={{ maxHeight: 'calc(100% - 55px)', overflowY: 'auto',  color: '#fff', padding: '0 10px 0px 10px', border: 'none'}}
        />
        <WordCountText>{editor?.storage.characterCount.words()} ord</WordCountText>
        </RichTextEditor>
      </MantineProvider>
      <Stack direction="row" spacing={1}>
        <Button
          variant="contained"
          color="primary"
          sx={{ 
            marginBottom: '5px',
            alignSelf: 'flex-start',
          }}
          onClick={() => {
            if (editor?.getHTML() !== contentData?.content_body) {
              try {
                marketingContentMutation.mutate({ id: contentId, content_body: editor?.getHTML(), variant_id: variantId },
                  {
                    onSuccess: () => {
                      setLastSaved(new Date().toLocaleString());
                      toast.success('Content saved successfully!', { autoClose: 2000 });
                    }
                  }
                );
              } catch (error) {
                Swal.fire({
                  icon: 'error',
                  title: 'Failed to save content.',
                  text: 'Please try again.',
                });
              }
            }
          }}
          disabled={editor?.getHTML() === contentData?.content_body || marketingContentMutation.isPending}
        >
          Save
        </Button>
        {lastSaved && <div style={{ lineHeight: '2.25' }}>Last saved: {lastSaved}</div>}
      </Stack>
    </Box>
  );
};

export default ContentDetails; 