import { useQuery } from "@tanstack/react-query";
import { ax } from "../Utils";
import { MarketingChannel } from "../Types";



export const useMarketingChannels = () => {
  return useQuery<MarketingChannel[], Error>({
    queryKey: ['marketing', 'channels'],
    queryFn: () => ax.get('/marketing_channel/all/').then(res => res.data),
  });
};