import { RichTextEditor, Link } from '@mantine/tiptap';
import { Editor, useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import { MantineProvider } from '@mantine/core';
import { useEffect } from 'react';
import { SaveMarketingButton, TextEditorBottomSection, ToolsGroup, WordCountText } from '../MarketingPage-styling';
import {FaAlignCenter, FaAlignJustify, FaAlignLeft, FaAlignRight, FaBold, FaHeading, FaHighlighter, FaItalic, FaListOl, FaListUl, FaRedo, FaStrikethrough, FaSubscript, FaSuperscript, FaUnderline, FaUndo} from "react-icons/fa";
import CharacterCount from '@tiptap/extension-character-count';
import { Marketing } from '../../Types';

type Props = {
  content: string;
  //onContentChange: (updatedContentBody: string, updatedContentText: string) => void;
  //wordCount: number;
  saveMarketing: () => void;
  handleChange: (html: string, text: string) => void;
  selectedMarketing: Marketing | null;
  selectedMarketingRef: React.MutableRefObject<Marketing | null>;
  cancelHandleChange: () => void;
  editorRef: React.MutableRefObject<Editor | null>;
};

function TextEditor(props: Props) {

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      CharacterCount,
    ],
    content: props.content,
    onUpdate: ({ editor }) => {
      // Call the onContentChange callback whenever the editor content changes
      if(props.selectedMarketingRef.current === props.selectedMarketing) {
        props.cancelHandleChange(); // Cancel the debounced function
      }
      else {
        props.selectedMarketingRef.current = props.selectedMarketing;
      }
      //props.onContentChange(editor.getHTML(), editor.getText());
      props.handleChange(editor.getHTML(), editor.getText());
    }
  });

  useEffect(() => {
    if (props.editorRef.current) {
      props.editorRef.current.commands.setContent(props.content.replace(/\n/g, '<br>'));
    }
  }, [props.content])

  useEffect(() => {
    console.log('editorRef');
    props.editorRef.current = editor;
    const proseMirrorElement = document.querySelector('.ProseMirror') as HTMLElement;
    const greatGrandparentElement = proseMirrorElement?.parentElement?.parentElement?.parentElement;
    
    if (proseMirrorElement){
      proseMirrorElement.style.outline = 'none';
    }
    const handleResize = () => {
      console.log('handleResize');
      if (proseMirrorElement && greatGrandparentElement) {
        const greatGrandparentHeight = greatGrandparentElement.clientHeight;
        const minHeightPercentage = 100; // Adjust the percentage as needed

        proseMirrorElement.style.minHeight = `${(minHeightPercentage / 100) * greatGrandparentHeight - 114}px`;
      }
    };

    // Initial setting of minHeight
    handleResize();

    // Listen for window resize event
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [editor]);


  return (
    <MantineProvider>
      <RichTextEditor 
        editor={editor}
        style={{
          height: 'calc(100% - 11px)',
          minHeight: '322px',
          minWidth: '210px',
          width: '50%',
          border: 'solid',
          borderColor: '#444', 
          borderWidth: '3px 3px 6px 6px', 
          borderRadius: '6px', 
          alignSelf: 'safe start',
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          position: 'relative',
        }}
        >
        <RichTextEditor.Toolbar style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: '#444', border: 'solid', borderColor: '#444', borderWidth: '3px 0px 3px 0px'}}>
          <ToolsGroup>
            <button
              onClick={() => editor?.chain().focus().toggleBold().run()}
              className={editor?.isActive('bold') ? 'is-active' : ''}
            >
              <FaBold />
            </button>
            <button
              onClick={() => editor?.chain().focus().toggleItalic().run()}
              className={editor?.isActive('italic') ? 'is-active' : ''}
            >
              <FaItalic />
            </button>
            <button
              onClick={() => editor?.chain().focus().toggleUnderline().run()}
              className={editor?.isActive('underline') ? 'is-active' : ''}
            >
              <FaUnderline />
            </button>
            <button
              onClick={() => editor?.chain().focus().toggleStrike().run()}
              className={editor?.isActive('strike') ? 'is-active' : ''}
            >
              <FaStrikethrough />
            </button>
            <button
              onClick={() => editor?.chain().focus().toggleHighlight().run()}
              className={editor?.isActive('highlight') ? 'is-active' : ''}
            >
              <FaHighlighter />
            </button>
            <button
              onClick={() => editor?.chain().focus().toggleHeading({ level: 1 }).run()}
              className={editor?.isActive('heading', { level: 1 }) ? 'is-active' : ''}
            >
              <FaHeading />
            </button>
            <button
              onClick={() => editor?.chain().focus().toggleSubscript().run()}
              className={editor?.isActive('subscript') ? 'is-active' : ''}
            >
              <FaSubscript />
            </button>
            <button
              onClick={() => editor?.chain().focus().toggleSuperscript().run()}
              className={editor?.isActive('superscript') ? 'is-active' : ''}
            >
              <FaSuperscript />
            </button>
          </ToolsGroup>

          <ToolsGroup>
            <button
              onClick={() => editor?.chain().focus().toggleBulletList().run()}
              className={editor?.isActive('bulletList') ? 'is-active' : ''}
            >
              <FaListUl />
            </button>
            <button
              onClick={() => editor?.chain().focus().toggleOrderedList().run()}
              className={editor?.isActive('orderedList') ? 'is-active' : ''}
            >
              <FaListOl />
            </button>
          </ToolsGroup>

          <ToolsGroup>
            <button
              onClick={() => editor?.chain().focus().setTextAlign('left').run()}
              className={editor?.isActive('textAlign', 'left') ? 'is-active' : ''}
            >
              <FaAlignLeft />
            </button>
            <button
              onClick={() => editor?.chain().focus().setTextAlign('center').run()}
              className={editor?.isActive('textAlign', 'center') ? 'is-active' : ''}
            >
              <FaAlignCenter />
            </button>
            <button
              onClick={() => editor?.chain().focus().setTextAlign('justify').run()}
              className={editor?.isActive('textAlign', 'justify') ? 'is-active' : ''}
            >
              <FaAlignJustify />
            </button>
            <button
              onClick={() => editor?.chain().focus().setTextAlign('right').run()}
              className={editor?.isActive('textAlign', { alignment: 'right'}) ? 'is-active' : ''}
            >
              <FaAlignRight />
            </button>
          </ToolsGroup>

          <ToolsGroup>
            <button 
              onClick={() => editor?.chain().focus().undo().run()}
              disabled={!editor?.can().undo()}
              className={editor?.can().undo() ? '' : 'is-disabled'}
            >
              <FaUndo/>
            </button>
            <button 
              onClick={() => editor?.chain().focus().redo().run()} 
              disabled={!editor?.can().redo()}
              className={editor?.can().redo() ? '' : 'is-disabled'}
            >
              <FaRedo/>
            </button>
          </ToolsGroup>
        </RichTextEditor.Toolbar>

        <RichTextEditor.Content
          style={{ maxHeight: 'calc(100% - 98px)', overflowY: 'auto',  color: '#fff', padding: '0 10px 0px 10px', border: 'none'}}
        />
        <TextEditorBottomSection>
          <WordCountText>{editor?.storage.characterCount.words()} ord</WordCountText>
          <SaveMarketingButton onClick={props.saveMarketing}>Spara</SaveMarketingButton>
        </TextEditorBottomSection>
      </RichTextEditor>
    </MantineProvider>
  );
};

export default TextEditor;


            // <RichTextEditor.Bold icon={FaBold} />
            // <RichTextEditor.Italic icon={FaItalic} />
            // <RichTextEditor.Underline icon={FaUnderline} />
            // <RichTextEditor.Strikethrough icon={FaStrikethrough} />
            // <RichTextEditor.Highlight icon={FaHighlighter} />
            // <RichTextEditor.H1 icon={FaHeading} />
            // <RichTextEditor.Subscript icon={FaSubscript} />
            // <RichTextEditor.Superscript icon={FaSuperscript} />

